import { api } from '@libs/api'
import { Alert, Snackbar } from '@mui/material'
import { Button } from '@stories/Theme/Button/Button'
import { Input } from '@stories/Theme/Input/Input'
import mixpanel from 'mixpanel-browser'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import AuthLayout from '../Layouts/AuthLayout'

const ForgotPassword = () => {
  useEffect(() => {
    mixpanel.track_pageview({ page: 'ForgotPassword' })
  }, [])
  const [isPosting, setIsPosting] = useState<boolean>(false)
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)
  const [responseMessage, setResponseMessage] = useState<string>('')

  const handleErrorClose = () => {
    setIsAlertOpen(false)
  }
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsPosting(true)
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    const { email } = Object.fromEntries(formData.entries()) as { email: string }
    api
      .resetPassword(email)
      .then((response) => {
        setIsAlertOpen(true)
        setResponseMessage(response.data.detail)
      })
      .finally(() => setIsPosting(false))
  }

  return (
    <AuthLayout>
      <Helmet>
        <title>Camperoni Website - forgot password</title>
        <link rel="canonical" href={`https://www.camperoni.com/forgotpassword`} />
      </Helmet>
      <form id={'forgot-password-form'} onSubmit={onSubmit}>
        <h1 className="auth-title">Forgot Password</h1>
        <h3 className="auth-subheading">Enter your email to get instruction to access your account</h3>
        <div className="auth-input">
          <label>Email</label>
          <Input type="text" name={'email'} placeholder="Enter your email" onChange={() => {}} />
        </div>
        <Button title="Send Email" mb="1rem" w={'100%'} rounded size="large" disabled={isPosting} />
      </form>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleErrorClose}
      >
        <Alert severity="success">{responseMessage}</Alert>
      </Snackbar>
    </AuthLayout>
  )
}

export default ForgotPassword
