import { api } from '@libs/api'
import Logout from '@mui/icons-material/Logout'
import Settings from '@mui/icons-material/Settings'
// import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import { Avatar } from '@stories/Theme/Avatar/Avatar'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import redis from '../../../libs/lockr.ts'
import { User } from '../../../libs/types/User.ts'

export const AuthMenu = () => {
  const navigator = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const logoutHandler = () => {
    const bc = new BroadcastChannel('logout')
    bc.postMessage('logout')
    api.logout()

    const nonPrivatePages = !['/settings', '/favorites', '/camps'].includes(location.pathname)
    if (nonPrivatePages) {
      return window.location.reload()
    }
    return navigator('/login')
  }
  useEffect(() => {
    const logoutChannel = new BroadcastChannel('logout')
    logoutChannel.addEventListener('message', (event) => {
      if (event.origin !== window.location.origin) {
        return
      }
      if (event.data === 'logout') {
        const nonPrivatePages = !['/settings', '/favorites', '/camps'].includes(location.pathname)
        if (nonPrivatePages) {
          return window.location.reload()
        }
        navigator('/login')
      }
    })
    return () => logoutChannel.close()
  }, [])
  function getAbbreviation(): string {
    const user = redis.get('user') as User
    if (!user) {
      return ''
    }
    return `${user.first_name.charAt(0)}.${user.last_name.charAt(0)}`.toUpperCase()
  }
  const abbreviation = getAbbreviation()
  return (
    <React.Fragment>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar>{abbreviation}</Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        disableScrollLock={true}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          style: {
            minWidth: '20ch',
          },
        }}
      >
        {/*<MenuItem onClick={handleClose} style={{ width: 200 }}>*/}
        {/*  <Avatar>{abbreviation}</Avatar>&nbsp; Profile*/}
        {/*</MenuItem>*/}
        {/*<Divider />*/}
        <MenuItem onClick={handleClose} component={Link} to={'/settings'}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={logoutHandler}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}
