import { Icons, IconsType } from '@assets/svg'
import { colors, SpacingProps, theme } from '@config/theme'
import React from 'react'
import styled, { css, ThemeProvider } from 'styled-components'

export type ButtonVariant = 'primary' | 'secondary' | 'share'
export type SizeVariant = 'large' | 'small'

export interface ButtonProps extends SpacingProps {
  /**
   * Button title.
   */
  title?: string | React.ReactNode | undefined | null
  /**
   * A string corresponding to the button-component variation classes
   */
  variant?: ButtonVariant
  /**
   * A string corresponding to the rounded variation
   */
  rounded?: boolean
  /**
   * A string corresponding to the rounded variation
   */
  disabled?: boolean
  /**
   * A string corresponding to the rounded variation
   */
  size?: SizeVariant
  /**
   * A React element to add a svg icon
   */
  icon?: React.ReactNode
  /**
   * A string showing the svg name from icons
   */
  svgName?: IconsType
  /**
   * A function to use for onClick
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /**
   * A function that adds styles for the component
   */
  style?: React.CSSProperties
  titleStyle?: React.CSSProperties
  /**
   * A string of className that will apply on the component
   */
  className?: string
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  mx?: string
  my?: string
  w?: string
  lineHeight?: string
  fullWidthOnSmallScreen?: boolean
}

const StyledButton = styled.button<{
  $variant?: string
  $rounded?: boolean
  disabled?: boolean
  $size?: string
  $mb?: number
  icon?: React.ReactNode
  svgName: IconsType | undefined
  mt?: string
  mb?: string
  ml?: string
  mr?: string
  mx?: string
  my?: string
  $title?: string | React.ReactNode | undefined | null
  $titleStyle?: React.CSSProperties
  lineHeight?: string
  fullWidthOnSmallScreen?: boolean
  w?: string
}>`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items:center;
  font-size: 0.8rem;
  padding: ${({ $title }) => ($title ? '0.2rem 1rem' : '0.5rem')} ;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  border-radius: ${({ $rounded }) => ($rounded === false ? '8px' : '50px')};
  outline: none;
  border: none;
  cursor: pointer;

  // Disabled State
  ${(props) =>
    props?.disabled &&
    css`
      pointer-events: none;
      opacity: 0.65;
    `};

  ${(props) => props.$mb && `margin-bottom: ${props.theme.spacing[props.$mb]};`};
  ${(props) => props.lineHeight && `line-height: ${props.lineHeight};`};
  ${({ $size }) =>
    $size === 'small'
      ? css`
          height: 2.4rem;
        `
      : css`
          height: 3rem;
        `}

  ${({ $size, $title }) =>
    $title
      ? css``
      : $size === 'small'
        ? css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 2.4rem;
            width: 2.4rem;
          `
        : css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 3rem;
            width: 3rem;
          `}
  ${({ $variant }) =>
    $variant === 'secondary' &&
    css`
      border: 1px solid ${colors.brandGray300};
    `}
  background-color: ${({ $variant }) => ($variant === 'primary' ? colors.brandBlue : $variant === 'share' ? colors.brandPurple : colors.brandWhite)};
  color: ${({ $variant }) => ($variant === 'primary' || $variant === 'share' ? colors.brandWhite : colors.brandGray700)};
  ${({ w }) =>
    w &&
    css`
      width: ${w};
    `}
  .title {
    cursor: pointer;
    text-align: center;
  }

  .icon {
    cursor: pointer;
  }

  ${({ icon, svgName }) =>
    (icon || svgName) &&
    css`
      .title {
        margin-left: 0.4rem;
        cursor: pointer;
        text-align: center;
      }
    `}
  &:hover {
  }

  &:active {
  }
  margin-top: ${(props) => props.mt || props.my || '0'};
  margin-bottom: ${(props) => props.mb || props.my || '0'};
  margin-left: ${(props) => props.ml || props.mx || '0'};
  margin-right: ${(props) => props.mr || props.mx || '0'};


  ${({ fullWidthOnSmallScreen }) =>
    fullWidthOnSmallScreen &&
    css`
      width: 100%;
      @media${({ theme }) => theme.device['md']} {
        width: auto;
      }
    `}
}
`

export const Button = ({
  variant = 'primary',
  size = 'small',
  disabled,
  title,
  titleStyle,
  rounded = false,
  onClick,
  icon,
  $mb,
  style,
  svgName,
  className,
  ml,
  mr,
  mt,
  mb,
  w,
  mx,
  my,
  lineHeight,
  fullWidthOnSmallScreen,
}: ButtonProps) => {
  let IconSVG = null
  if (svgName) {
    IconSVG = Icons[svgName]
  }
  return (
    <ThemeProvider theme={theme}>
      <StyledButton
        $variant={variant}
        $rounded={rounded}
        onClick={onClick}
        icon={icon}
        $size={size}
        $mb={$mb}
        style={style}
        svgName={svgName}
        className={className}
        disabled={disabled}
        ml={ml}
        mr={mr}
        mb={mb}
        w={w}
        mt={mt}
        mx={mx}
        my={my}
        $title={title}
        $titleStyle={titleStyle}
        lineHeight={lineHeight}
        fullWidthOnSmallScreen={fullWidthOnSmallScreen}
      >
        {icon && !IconSVG && <>{icon}</>}
        {IconSVG && <IconSVG />}
        {title && (
          <span className="title" style={titleStyle}>
            {title}
          </span>
        )}
      </StyledButton>
    </ThemeProvider>
  )
}
