import { getLastDate } from '@decorators/number-formatter.ts'
import { apiResponse } from '@libs/api'

import { Camp } from './types/Camp.ts'

export type AcceptedValue = string | number | boolean | object | Array<AcceptedValue> | undefined

interface IStorage {
  get(key: string, missing?: AcceptedValue): AcceptedValue

  set(key: string, value: AcceptedValue): void

  setEx(key: string, ttl: number, value: AcceptedValue): void

  getOrSetEx(callable: () => AcceptedValue, key: string, ttl: number): AcceptedValue

  expires(key: string, ttl: number): void

  ttl(key: string): number

  sadd(key: string, ...values: AcceptedValue[]): void

  srem(key: string, index: number): AcceptedValue

  smembers(key: string): AcceptedValue[]

  incrby(key: string, value: number): number

  incr(key: string): number

  lpush(key: string, value: AcceptedValue): void

  rpush(key: string, value: AcceptedValue): void

  lpop(key: string): AcceptedValue

  rpop(key: string): AcceptedValue

  rm(key: string): number

  del(...key: string[]): number

  flush(): void
}

class Storage implements IStorage {
  private storage

  public constructor(getStorage = () => localStorage) {
    this.storage = getStorage()
  }

  private tryParse = (str: string): { data: AcceptedValue; expires: number } | false => {
    if (!str) {
      return false
    }
    try {
      const o = JSON.parse(str)
      if (o && typeof o === 'object') {
        return o
      }
    } catch (error) {
      console.warn(`Failed to parse ${str}`)
    }
    return false
  }

  public get(key: string, missing: AcceptedValue = undefined): AcceptedValue {
    const now = Math.round(Date.now() / 1000)
    const item = this.storage.getItem(key)
    if (!item) {
      return missing
    }

    const value = this.tryParse(item)

    if (value === false) {
      return item ?? missing
    }

    if (value.expires && now > value.expires) {
      return undefined
    }

    return value.data ?? missing
  }

  private getArray(key: string): any {
    let data = this.get(key)
    if (!Array.isArray(data)) {
      data = []
    }
    return data
  }

  public set(key: string, value: AcceptedValue): void {
    return this.storage.setItem(key, JSON.stringify({ data: value }))
  }

  public setEx(key: string, ttl: number, value: AcceptedValue): void {
    const now = Math.round(Date.now() / 1000)
    const item = {
      data: value,
      expires: now + ttl,
    }
    return this.storage.setItem(key, JSON.stringify(item))
  }

  public async getOrSetEx(
    callable: (args?: any) => Promise<apiResponse<AcceptedValue>>,
    key: string,
    ttl: number,
    args?: any
  ): Promise<AcceptedValue> {
    let value = this.get(key)
    if (value) {
      return value
    }

    const response = await callable(args)
    if (response.status === 200) {
      if (key === 'api:camps') {
        ;(response.data as Camp[]).forEach((camp) => {
          // @ts-ignore
          delete camp?.description
          // @ts-ignore
          delete camp?.city
          // @ts-ignore
          delete camp?.extended_earliest_dropoff_time
          // @ts-ignore
          delete camp?.extended_latest_pickup_time
          delete camp?.raw_ages_or_grades
          delete camp?.registration_link
          // @ts-ignore
          delete camp?.purchase_options
          delete camp?.registration_open_time
          delete camp?.registration_close_time
        })

        response.data = (response.data as []).filter((camp: Camp) => {
          const dates = camp.dates
          if (!dates) {
            return false
          }
          return getLastDate(dates) >= new Date().toISOString().split('T')[0]
        })
      }
      this.setEx(key, ttl, response as AcceptedValue)
      value = this.get(key)
      return value
    }
  }

  public expires(key: string, ttl: number): boolean {
    const item = this.storage.getItem(key)
    if (!item) {
      return false
    }

    const value = this.tryParse(item)
    if (value === false) {
      return false
    }

    const now = Math.round(Date.now() / 1000) + ttl
    this.storage.setItem(key, JSON.stringify({ data: value.data, expires: now }))
    return true
  }

  public ttl(key: string): number {
    const item = this.storage.getItem(key)
    if (!item) {
      return -2
    }
    const value = this.tryParse(item)
    if (value === false) {
      return -1
    }
    const now = Math.round(Date.now() / 1000)
    if (!value?.expires) {
      return -1
    }
    if (value.expires - now < 0) {
      return -1
    }
    return value?.expires - now
  }

  public rm(key: string): number {
    return this.del(key)
  }

  public del(...keys: string[]): number {
    let idx = 0
    keys.forEach((key, index) => {
      this.storage.removeItem(key)
      idx = index + 1
    })
    return idx
  }

  public flush(): void {
    if (this.storage.clear) {
      this.storage.clear()
    }
  }

  public sadd(key: string, ...values: AcceptedValue[]): number {
    let idx = 0
    values.forEach((value, index) => {
      this.rpush(key, value)
      idx = index + 1
    })
    return idx
  }

  public srem(key: string, index: number): AcceptedValue {
    const arr = this.getArray(key)
    const item = arr[index]
    delete arr[index]
    this.set(key, arr.filter(Boolean))
    return item
  }

  public smembers(key: string): AcceptedValue[] {
    return this.getArray(key)
  }

  public lpush(key: string, value: AcceptedValue): void {
    const data = this.getArray(key)
    data.unshift(value)
    this.set(key, data)
  }

  public rpush(key: string, value: AcceptedValue) {
    const data = this.getArray(key)
    data.push(value)
    this.set(key, data)
  }

  public lpop(key: string): AcceptedValue {
    const data = this.getArray(key)
    const value = data.shift()
    this.set(key, data)
    return value
  }

  public rpop(key: string): AcceptedValue {
    const data = this.getArray(key)
    const value = data.pop()
    this.set(key, data)
    return value
  }

  incrby(key: string, value: number): number {
    let data = this.get(key) as number
    if (!Number.isInteger(data)) {
      data = 0
    }
    this.set(key, data + value)
    return data + value
  }

  incr(key: string): number {
    return this.incrby(key, 1)
  }

  setUtmTags(searchParams: URLSearchParams) {
    if (searchParams.get('utm_campaign')) {
      this.setEx('utm_campaign', 86400, searchParams.get('utm_campaign') as string)
    }
    if (searchParams.get('utm_source')) {
      this.setEx('utm_source', 86400, searchParams.get('utm_source') as string)
    }
    if (searchParams.get('utm_medium')) {
      this.setEx('utm_medium', 86400, searchParams.get('utm_medium') as string)
    }
    if (searchParams.get('utm_term')) {
      this.setEx('utm_term', 86400, searchParams.get('utm_term') as string)
    }
    if (searchParams.get('utm_content')) {
      this.setEx('utm_content', 86400, searchParams.get('utm_content') as string)
    }
  }
}

const redis = new Storage()
export default redis
