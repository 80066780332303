import { colors } from '@config/theme'
import styled from 'styled-components'

const AuthLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .auth-nav {
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    padding: 18px 24px;
    @media${({ theme }) => theme.device['md']} {
      padding: 30px;
    }
  }
  .auth-content {
    max-width: 390px;
    padding: 16px;
    width: 100%;
    position: relative;
    z-index: 5;
    .auth-title {
      color: var(${colors.brandDefault});
      text-align: center;
      font-family: Ubuntu;
      margin-top: 36px;
      margin-bottom: 8px;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px; /* 126.667% */
      letter-spacing: -1.5px;
      &.register-title {
        //margin-top: 1rem;
      }
      @media ${({ theme }) => theme.device.sm} {
        margin-top: 24px;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -1.8px;
        margin-bottom: 16px;
      }
    }
    .auth-subheading {
      color: var(--gray-600, #475467);
      text-align: center;
      margin-bottom: 32px;
      @media ${({ theme }) => theme.device.sm} {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .auth-input {
      width: 100%;
      margin-bottom: 24px;
      label {
        color: var(--gray-700, #344054);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-bottom: 6px;
        display: block;
      }
      input {
        width: 100%;
      }
    }
    .password-checker {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      margin-bottom: 12px;
      span {
        color: var(--gray-600, #475467);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    .remember-me-section {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 24px;
      width: 100%;
      .check-box {
        display: flex;
        align-items: center;
        span {
          padding: 0px 4px 0px 0px;
        }
        label {
          color: var(--gray-700, #344054);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
      .forgot-link {
        color: var(--brand-700, #323d77);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
    .auth-move-link {
      margin-top: 32px;
      text-align: center;
      color: var(--gray-600, #475467);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      a {
        color: var(--brand-700, #323d77);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
  }
`

export { AuthLayoutWrapper }
