import styled from 'styled-components'

import noCampImageFallback from '../../assets/media/images/no-camp-image-fallback.jpg'

const CampPageImageWrapper = styled.section`
  width: 100%;

  img {
    width: 100%;
    position: sticky;
    top: 1rem;
  }
`

const CampPageImage = (props: { imageUrl: string }) => {
  const imageUrl = props.imageUrl

  if (!imageUrl) {
    return null
  }

  return (
    <CampPageImageWrapper>
      <img
        src={imageUrl}
        alt={'main image'}
        loading={'lazy'}
        width={'100%'}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = noCampImageFallback
        }}
      />
    </CampPageImageWrapper>
  )
}

export default CampPageImage
