import { createSlice } from '@reduxjs/toolkit'

import { Schoolbreak } from '../../libs/types/Schoolbreak.ts'

export interface SchoolBreaksState {
  schoolBreaksList: Schoolbreak[]
  isLoading: boolean
}

export const initialState: SchoolBreaksState = {
  schoolBreaksList: [],
  isLoading: false,
}

export const schoolBreaksReducer = createSlice({
  name: 'schoolBreaks',
  initialState,
  reducers: {
    setSchoolBreakLoading: (state, action) => {
      state.isLoading = action.payload
    },
    getSchoolBreakList: () => {},
    setSchoolBreakList: (state, action) => {
      state.schoolBreaksList = action.payload
    },
  },
})

export const { getSchoolBreakList, setSchoolBreakList, setSchoolBreakLoading } = schoolBreaksReducer.actions

export default schoolBreaksReducer.reducer
