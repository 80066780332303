import { Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  width: 100%;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
`
const Error404Skeleton: React.FC = () => {
  return (
    <Box>
      <StyledBox display={'flex'} pb={3}>
        <Skeleton variant="rounded" width={400} height={400} />
      </StyledBox>
    </Box>
  )
}

export default Error404Skeleton
