import Eventbus from '@libs/eventbus'
import { SlotComponentProps } from '@mui/base'
import { Box, FormControlLabel, Slider, Switch } from '@mui/material'
import { SliderComponentsPropsOverrides, SliderOwnerState } from '@mui/material/Slider/Slider'
import { setPricePerDayRange, setPricePerDayScholarshipsOnly } from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useEffect, useState } from 'react'

const marks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 350,
    label: '$350',
  },
]

function valuetext(value: number) {
  return `$${value} `
}

const PricePerDay = (): JSX.Element => {
  const dispatch = useAppDispatch()

  const value = useAppSelector((state) => state.campFilters.filters.pricePerDay.range)
  const scholarshipsOnly = useAppSelector((state) => state.campFilters.filters.pricePerDay.scholarshipsOnly)

  const [tempValue, setTempValue] = useState<number[]>(value)

  useEffect(() => {
    setTempValue(value)
  }, [value])

  const handleChange = (_event: Event, newTempValue: number | number[]) => {
    setTempValue(newTempValue as number[])
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handlePricePerDayRangeChange = (_event: React.SyntheticEvent | Event, newValue: number | number[]) => {
    dispatch(setPricePerDayRange(newValue))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Price Per Day Range',
      value: newValue,
    })
  }

  function handleScholarshipsOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
    dispatch(setPricePerDayScholarshipsOnly(event.target.checked))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Scholarships Only',
      value: event.target.checked,
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  return (
    <>
      <Box px={3} pt={4} pb={2}>
        <Slider
          slotProps={{
            input: {
              'data-testid': 'slider-price',
            } as SlotComponentProps<'input', SliderComponentsPropsOverrides, SliderOwnerState>,
          }}
          disableSwap
          getAriaLabel={() => 'Price per day'}
          getAriaValueText={valuetext}
          marks={marks}
          max={350}
          min={0}
          onChange={handleChange}
          onChangeCommitted={handlePricePerDayRangeChange}
          step={10}
          value={tempValue}
          valueLabelDisplay="on"
          valueLabelFormat={valuetext}
        />
      </Box>
      <FormControlLabel
        control={<Switch onChange={handleScholarshipsOnlyChange} sx={{ m: 1 }} checked={scholarshipsOnly} />}
        label="Scholarships only"
      />
    </>
  )
}

export default PricePerDay
