import { decorateInterestsToTree } from '@decorators/decorateInterestsToTree.ts'
import { api } from '@libs/api'
import { call, put, takeLatest } from 'redux-saga/effects'

import { InterestList } from '../../libs/types/Interest.ts'
import {
  // applyFilters,
  getInterestList,
  getProviderList,
  setInterestList,
  setProviderList,
} from './campFiltersSlice'

function* loadDataFromAPI() {
  const { data: interests } = yield call(api.getInterests)
  const { data: providers } = yield call(api.getProviders)

  const interestList: InterestList[] = yield call(decorateInterestsToTree, interests)

  yield put(setInterestList(interestList))
  yield put(setProviderList(providers))
}

export default function* campFiltersSaga() {
  yield takeLatest([getInterestList, getProviderList], loadDataFromAPI)
}
