import {
  useAdditionalOptionsFilters,
  useAudienceFilters,
  useCitiesFilters,
  useDateRangeFilters,
  useDayLengthFilters,
  useDistanceFilters,
  useInterestsFilters,
  useKidsAgesFilters,
  usePricePerDayFilters,
  useProviderFilters,
  useRegistrationStatusFilters,
  useSchoolBreaksFilters,
} from '@hooks'
import { api } from '@libs/api'
import { Box, Grid, Hidden } from '@mui/material'
import { getProviderList, resetFilters } from '@store/campFilters/campFiltersSlice'
import { getSchoolBreakList } from '@store/schoolBreaks/schoolBreaksSlice.ts'
import { FC, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import CampListSearchFilterMobile from '../components/CampList/CampListSearchFilterMobile'
import CampListSidebar from '../components/CampList/CampListSidebar/index'
import Footer from '../components/Footer'
import Header from '../components/Header'
import redis from '../libs/lockr.ts'
import { getCampList } from '../store/campSearch/campSearchSlice'
import { useAppDispatch } from '../store/hooks.ts'
import { getUserList } from '../store/userList/userListSlice'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const MainContainer = styled.main`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`

const FooterContainer = styled.footer`
  margin-top: auto;
`
const ListPageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const isLoggedIn = api.isUserLoggedIn()

  useEffect(() => {
    if (!isLoggedIn) {
      redis.setEx('redirectUrl', 3600, window.location.pathname + window.location.search)
      return navigator('/login')
    }
    if (window.location.search.includes('filter_') || window.location.search.includes('search')) {
      dispatch(resetFilters())
    }
    dispatch(getSchoolBreakList())
    dispatch(getProviderList())
    dispatch(getUserList())
    dispatch(getCampList())
  }, [])

  if (isLoggedIn) {
    useAdditionalOptionsFilters()
    useCitiesFilters()
    useSchoolBreaksFilters()
    useDayLengthFilters()
    useDistanceFilters()
    useInterestsFilters()
    useKidsAgesFilters()
    usePricePerDayFilters()
    useProviderFilters()
    useRegistrationStatusFilters()
    useDateRangeFilters()
    useAudienceFilters()
  }

  return (
    <>
      <Wrapper>
        <Header pageTitle={'Camp Search'} />
        <MainContainer>
          <Box pt={10}>
            <Grid container spacing={4}>
              <Hidden lgDown>
                <Grid item lg={3.5}>
                  <CampListSidebar />
                </Grid>
              </Hidden>
              <Grid item xs={12} lg={8.5}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </MainContainer>
        <FooterContainer>
          <Footer />
        </FooterContainer>
      </Wrapper>
      <Hidden lgUp>
        <CampListSearchFilterMobile />
      </Hidden>
    </>
  )
}

export default ListPageLayout
