import { Icons } from '@assets/svg'
import { colors } from '@config/theme'
import { api } from '@libs/api'
import { Dialog, DialogTitle } from '@mui/material'
import { useAppDispatch } from '@store/hooks.ts'
import { getUserList } from '@store/userList/userListSlice.ts'
import { CloseButton } from '@stories/Icons/CloseButton/CloseButton.tsx'
import { Button } from '@stories/Theme/Button/Button'
import { Input } from '@stories/Theme/Input/Input'
import mixpanel from 'mixpanel-browser'
import { FC, useEffect, useRef } from 'react'
import styled from 'styled-components'

import redis from '../../../libs/lockr.ts'

interface CreateNewListDialogProps {
  open: boolean
  setOpen: (visible: boolean) => void
}

const CreateNewListDialogWrapper = styled(Dialog)`
  .dialog-body {
    padding: 0 1rem 1rem;

    .auth-input {
      padding: 1.5rem 0;
      border-top: 1px solid ${colors.brandGray300};
      border-bottom: 1px solid ${colors.brandGray300};
      margin-bottom: 1rem;
      min-width: 300px;

      @media (min-width: 1280px) {
        min-width: 375px;
      }

      label {
        margin-bottom: 0.5rem;
        display: block;
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      button {
        font-size: 1rem;
        border: 1px solid #a9b6fc;
      }
    }
  }
`

const CreateNewListDialog: FC<CreateNewListDialogProps> = ({ open, setOpen }) => {
  const inputRef = useRef(null)
  const dispatch = useAppDispatch()
  const onSave = async () => {
    if (inputRef.current) {
      const inputField: any = inputRef.current
      const response = await api.createUserList(inputField.value)
      if (response.status === 200 || response.status === 201) {
        redis.rm('api:userList')
        dispatch(getUserList())
        setOpen(false)
        mixpanel.track('Created New List')
      }
    }
  }

  useEffect(() => {
    if (open) {
      mixpanel.track('Create New List Button')
    }
  }, [open])

  return (
    <CreateNewListDialogWrapper onClose={() => setOpen(false)} open={open}>
      <DialogTitle alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
        Create a new camp list
        <CloseButton onClick={() => setOpen(false)} />
      </DialogTitle>
      <div className="dialog-body">
        <div className="auth-input">
          <Input inputRef={inputRef} type="text" placeholder="Enter List Name" autoFocus />
        </div>
        <div className="button-wrapper">
          <Button variant="secondary" icon={<Icons.SaveButtonIcon />} onClick={() => onSave()} title={'Save'} />
        </div>
      </div>
    </CreateNewListDialogWrapper>
  )
}

export default CreateNewListDialog
