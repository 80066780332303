import { Skeleton } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  width: 100%;
  flex-direction: column;
  padding: 1rem;

  span {
    max-width: 100%;
  }

  div {
    width: 100%;
    max-width: 100%;
    @media${({ theme }) => theme.device['lg']} {
      flex-direction: row;
    }
  }

  @media${({ theme }) => theme.device['lg']} {
    padding: 4rem 5rem;
    flex-direction: row;
    .left-side {
      padding: 9rem 4rem;
    }
  }
`
const HomePageSkeleton: React.FC = () => {
  return (
    <Box>
      <StyledBox
        display={'flex'}
        sx={{
          gap: 1,
        }}
        pb={3}
      >
        <div className={'left-side'}>
          <Skeleton variant="rectangular" height={'21px'} style={{ marginBottom: '0.75rem' }} />
          <Skeleton variant="rectangular" height={'48px'} style={{ marginBottom: '0.5rem' }} />
          <Skeleton variant="rectangular" height={'48px'} style={{ marginBottom: '0.5rem' }} />
          <Skeleton variant="rectangular" height={'48px'} style={{ marginBottom: '0.5rem' }} />
          <Skeleton variant="rectangular" height={'21px'} style={{ marginBottom: '0.5rem' }} />
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="rectangular" width={280} height={'24px'} style={{ marginBottom: '0.5rem' }} />
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="rectangular" width={280} height={'24px'} style={{ marginBottom: '0.5rem' }} />
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="rectangular" width={280} height={'24px'} style={{ marginBottom: '0.5rem' }} />
          </Box>
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Skeleton variant="circular" width={24} height={24} />
            <Skeleton variant="rectangular" width={280} height={'24px'} style={{ marginBottom: '0.5rem' }} />
          </Box>
        </div>
        <div className="right-side">
          <Skeleton variant="rounded" width={640} height={640} style={{ marginBottom: '0.75rem' }} />
        </div>
      </StyledBox>
      <Box mb={1}>
        <Skeleton variant="rectangular" height={100} />
      </Box>
      <Box mb={1}>
        <Skeleton variant="rectangular" height={100} />
      </Box>
      <Box mb={1}>
        <Skeleton variant="rectangular" height={100} />
      </Box>
    </Box>
  )
}

export default HomePageSkeleton
