import { FC } from 'react'

import { EllipseEnum, EllipseProps } from '../../libs/types/Ellipse'
import { EllipseWrapper } from './style'

const Ellipse: FC<EllipseProps> = ({ type = EllipseEnum['default'] }) => {
  return <EllipseWrapper type={type} />
}

export default Ellipse
