import { Icons } from '@assets/svg'
import { Alert, Snackbar } from '@mui/material'
import { Button, SizeVariant } from '@stories/Theme/Button/Button'
import { useState } from 'react'
import { RxHeart } from 'react-icons/rx'
import styled from 'styled-components'

import Eventbus from '../../libs/eventbus.ts'

const ShareIcon = Icons['share']
const AddToListAndShareButtonsWrapper = styled.section`
  display: flex;

  .add-to-lists-button {
    font-size: 1.08rem;
    margin-right: 18px;
  }

  .share-button {
    display: flex;
    font-size: 1.08rem;
  }
`

const AddToListAndShareButtons = (props: {
  size: SizeVariant
  withShareButton: boolean
  className?: string
  onClick: () => void
}) => {
  const size = props.size
  const withShareButton = props.withShareButton
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false)

  return (
    <AddToListAndShareButtonsWrapper className={props.className}>
      <Button
        className="add-to-lists-button"
        onClick={props.onClick}
        icon={<RxHeart />}
        rounded={true}
        size={size}
        style={{ margin: '0 10px 0 0' }}
        title="Add to lists"
        variant="primary"
      />
      {withShareButton && (
        <Button
          variant="share"
          rounded
          title={'Share'}
          icon={<ShareIcon color="white" />}
          className="add-to-lists-button"
          size={size}
          style={{ margin: '0 10px 0 0' }}
          onClick={() => {
            navigator.clipboard
              .writeText(window.location.href)
              .then(() => {
                Eventbus.trigger(Eventbus.MIXPANEL_CTA_CLICKED, {
                  value: 'Clicked Share Camp',
                })
                setIsSnackbarOpen(true)
              })
              .catch(() => {
                console.warn('Could not write data to clipboard')
              })
          }}
        />
      )}
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setIsSnackbarOpen(false)
        }}
      >
        <Alert severity="success">Link copied successfully, share it, don&apos;t hold back!</Alert>
      </Snackbar>
    </AddToListAndShareButtonsWrapper>
  )
}

export default AddToListAndShareButtons
