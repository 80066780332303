import { setDistance } from '@store/campFilters/campFiltersSlice.ts'
import { useAppDispatch } from '@store/hooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useDistanceFilters = (): void => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  const filter_distance = searchParams.get('filter_distance')

  useEffect(() => {
    if (filter_distance && parseInt(filter_distance)) {
      dispatch(setDistance(filter_distance))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_distance')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_distance])
}
