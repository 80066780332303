import { Skeleton, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import { FC } from 'react'

import AuthLayout from '../Layouts/AuthLayout'

const LoginSkeleton: FC = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const labelWidth = '350px'

  return (
    <AuthLayout>
      <Skeleton variant="rounded" width={labelWidth} height={'320px'} />

      <Box
        sx={{ padding: isSmallScreen ? '1rem 0.5rem' : '1.5rem 0.5rem', gap: 1 }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'end'}
        pb={3}
      >
        <Skeleton variant="text" width={labelWidth} height={40} style={{ marginBottom: '1rem' }} />

        <Skeleton variant="rounded" width={labelWidth} height={40} />

        <Skeleton variant="rounded" width={labelWidth} height={40} />
        <Skeleton variant="text" width={'100px'} height={20} />

        <Skeleton variant="text" width={labelWidth} height={20} />

        <Skeleton variant="rounded" width={labelWidth} height={50} style={{ borderRadius: '100px' }} />

        <Skeleton variant="rounded" width={labelWidth} height={50} style={{ borderRadius: '100px' }} />
      </Box>
    </AuthLayout>
  )
}

export default LoginSkeleton
