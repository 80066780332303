import { nextHour } from '@decorators/number-formatter.ts'
import Eventbus from '@libs/eventbus'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { useEffect } from 'react'
import styled from 'styled-components'

const AddCalendarWrapper = styled.section`
  display: flex;
`

export interface AddCalendarData {
  id: number
  providerId: number | undefined
  name: string
  location: string
  startDate: string | undefined
  endDate: string | undefined
  startTime: string | undefined
  endTime: string | undefined
  registrationOpenDate: string | undefined
  registrationOpenTime: string | undefined
  registrationCloseDate: string | undefined
  registrationCloseTime: string | undefined
  registrationLink: string | undefined
}

const AddCalendar = (props: { data: AddCalendarData }) => {
  const data: AddCalendarData = props.data
  const availability = data.registrationOpenTime ? 'busy' : 'free'

  const getCalendarName = (name: string): string => {
    return `Registration for: ${name}`
  }

  const getCalendarDescription = (data: AddCalendarData): string => {
    const description = []
    if (data.registrationLink) {
      description.push(`Register here: [url]${data.registrationLink}[/url]\n\n`)
    }
    description.push(`Calendar reminder downloaded from www.camperoni.com.\n`)
    description.push(
      `Dates and times based on provider website at time of download but may change, so be sure to check for any updates.\n\n`
    )
    description.push(`Further camp details can be found at: [url]https://www.camperoni.com/camps/${data.id}[/url]`)

    return description.join('')
  }
  const sendMixPanelEvent = (event: MouseEvent) => {
    if ((event.target as HTMLDivElement).className !== 'atcb-light') {
      return
    }
    Eventbus.trigger(Eventbus.MIXPANEL_CTA_CLICKED, {
      value: 'Clicked Shared Calendar Event',
      properties: {
        'Camp ID': data.id,
        'Provider ID': data.providerId,
      },
    })
  }

  useEffect(() => {
    document.addEventListener('click', sendMixPanelEvent)
    return () => {
      document.removeEventListener('click', sendMixPanelEvent)
    }
  }, [])

  return (
    <AddCalendarWrapper>
      {data && data.startDate && (
        <AddToCalendarButton
          name={getCalendarName(data.name)}
          options={['Apple', 'Google', 'iCal', 'Outlook.com', 'Yahoo']}
          location={data.location}
          startDate={data.registrationOpenDate}
          endDate={data.registrationOpenDate}
          startTime={data.registrationOpenTime}
          endTime={nextHour(data.registrationOpenTime)}
          description={getCalendarDescription(data)}
          availability={availability}
          timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        />
      )}
    </AddCalendarWrapper>
  )
}

export default AddCalendar
