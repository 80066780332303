import { brandGray300 } from '@config/theme'
import Eventbus from '@libs/eventbus'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { Box, Button, Divider, Hidden, IconButton, useTheme } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import {
  applyFilters,
  resetFilters,
  setAdditionalOptions,
  setCheckedInterests,
  setCheckedRequirements,
  setCities,
  setDateRange,
  setDayLength,
  setDistance,
  setKidsAges,
  setPricePerDay,
  setProviders,
  // setRegistrationStatus,
  setSchoolBreaks,
} from '@store/campFilters/campFiltersSlice'
import { toggleFilterDrawer } from '@store/campSearch/campSearchSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import { ActiveFilterIcon } from '@stories/Icons/ActiveFilterIcon/ActiveFilterIcon.tsx'

import AdditionalOptions from './Filters/AdditionalOptions.tsx'
import AudienceFilter from './Filters/Audiencefilter.tsx'
import CalendarRangeFilter from './Filters/CalendarRangeFilter.tsx'
import CitiesFilter from './Filters/CitiesFilter.tsx'
import DayLength from './Filters/DayLength.tsx'
import Distance from './Filters/Distance.tsx'
// import ProviderFilter from './Filters/Provider.tsx'
import InterestsFilter from './Filters/InterestsFilter.tsx'
// import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import KidsAges from './Filters/KidsAges.tsx'
import PricePerDay from './Filters/PricePerDay.tsx'
import ProviderFilter from './Filters/Provider.tsx'
import SchoolBreaksFilter from './Filters/SchoolBreaksFilter.tsx'
// import RegistrationStatus from './Filters/RegistrationStatus.tsx'
import Sort from './Filters/Sort.tsx'

export default function CampListSidebar() {
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const campListFiltered = useAppSelector((state) => state.campSearch.campListFiltered)
  const filters = useAppSelector((state) => state.campFilters.filters)
  const isZipCodeIncluded = useAppSelector((state) => state.campFilters.filters.distance.isZipCodeIncluded)

  const handleResetFilters = (): void => {
    dispatch(resetFilters())
    Eventbus.trigger(Eventbus.RESET_FILTERS)
    Eventbus.trigger(Eventbus.DISTANCE_CLEAR_FILTER)

    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Reset All Filters',
    })
    dispatch(applyFilters())
  }

  const handleFilterDrawerToggle = (): void => {
    dispatch(toggleFilterDrawer())
  }

  function isFilterActiveRenderClearFilter(
    selectedFilter: any,
    defaultValue: any,
    clearCallback: ActionCreatorWithPayload<any, any>,
    filterCategoryName: string
  ) {
    const isFilterActive = (filter: any, defaultValue: any): boolean => {
      return JSON.stringify(filter) !== JSON.stringify(defaultValue)
    }

    return (
      <>
        {isFilterActive(selectedFilter, defaultValue) && (
          <Box display={'flex'} justifyContent={'space-between'} flex={1} alignItems={'center'}>
            <ActiveFilterIcon />

            <Button
              color="inherit"
              startIcon={<RefreshOutlinedIcon />}
              sx={{ mr: 1, lineHeight: '20px' }}
              className={'clear-filter-button'}
              onClick={(event) => {
                event.stopPropagation()
                dispatch(clearCallback(defaultValue))
                Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
                  filter: 'Clear filter',
                  value: filterCategoryName,
                })

                if (filterCategoryName === 'Date') {
                  Eventbus.trigger(Eventbus.CALENDAR_CLEAR_FILTER)
                }
              }}
            >
              Clear filter
            </Button>
          </Box>
        )}
      </>
    )
  }

  return (
    <Box
      position={'sticky'}
      top={'1rem'}
      bottom={'1rem'}
      overflow={'auto'}
      className="custom-scrollbar sidebar-sticky-drawer"
      pt={{
        xs: 0,
        lg: 0,
      }}
    >
      <Hidden lgUp>
        <Box
          position={'sticky'}
          top={0}
          bgcolor={'#fff'}
          zIndex={10}
          pt={2}
          px={{
            xs: 2,
            lg: 4,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
            <Typography variant="h6">Sort & filters</Typography>
            <IconButton onClick={handleFilterDrawerToggle} color="primary">
              <CloseRoundedIcon />
            </IconButton>
          </Box>
          <Divider />
        </Box>
      </Hidden>
      <Box>
        <Box
          px={{
            xs: 2,
            lg: 4,
          }}
        >
          <Hidden lgUp>
            <Accordion
              defaultExpanded={true}
              elevation={0}
              sx={{ borderTop: `1px solid ${brandGray300}`, borderRadius: '0 !important' }}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-sorting-content"
                id="panel-sorting-header"
                sx={{ px: 0 }}
              >
                <Typography fontWeight={600}>Sort</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ px: 0 }}>
                <Box mb={4}>
                  <Sort fullWidth />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Hidden>

          <Accordion
            defaultExpanded={true}
            elevation={0}
            sx={{ borderTop: `1px solid ${brandGray300}`, borderRadius: '0 !important' }}
            disableGutters
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-age-content"
              id="panel-age-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.5rem'} height={'2rem'}>
                Kids age(s)
              </Typography>
              {isFilterActiveRenderClearFilter(filters.kidsAges, [], setKidsAges, 'Kids age(s)')}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <Typography variant="body2" mb={2}>
                Choosing multiple ages will show camps for <br /> <strong>either</strong> kid.
              </Typography>
              <KidsAges />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ borderTop: `1px solid ${brandGray300}` }} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-distance-filter-content"
              id="panel-distance-filter-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.5rem'} height={'2rem'}>
                Distance
              </Typography>
              {isZipCodeIncluded && (
                <Box display={'flex'} justifyContent={'space-between'} flex={1} alignItems={'center'}>
                  <ActiveFilterIcon />

                  <Button
                    color="inherit"
                    startIcon={<RefreshOutlinedIcon />}
                    sx={{ mr: 1, lineHeight: '20px' }}
                    className={'clear-filter-button'}
                    onClick={(event) => {
                      event.stopPropagation()
                      Eventbus.trigger(Eventbus.DISTANCE_CLEAR_FILTER)
                      dispatch(
                        setDistance({
                          zipCode: '',
                          miles: 100,
                          allowOvernight: true,
                          allowBussing: true,
                          isZipCodeIncluded: false,
                        })
                      )
                    }}
                  >
                    Clear filter
                  </Button>
                </Box>
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <Distance />

              <Typography variant="body2" mb={2}></Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ borderTop: `1px solid ${brandGray300}` }} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-date-content"
              id="panel-date-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.66rem'} height={'2rem'}>
                Dates
              </Typography>
              {isFilterActiveRenderClearFilter(filters.dateRange, ['', ''], setDateRange, 'Date')}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>{<CalendarRangeFilter />}</AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ borderTop: `1px solid ${brandGray300}` }} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-date-filter-content"
              id="panel-date-filter-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.66rem'} height={'2rem'}>
                School breaks
              </Typography>
              {isFilterActiveRenderClearFilter(filters.schoolBreaks, [], setSchoolBreaks, 'School breaks')}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <SchoolBreaksFilter />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ borderTop: `1px solid ${brandGray300}` }} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-day-length-filter-content"
              id="panel-day-length-filter-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.66rem'} height={'2rem'}>
                Day length
              </Typography>
              {isFilterActiveRenderClearFilter(
                filters.dayLength,
                {
                  dropOffTimeAm: [4.3, 23.644646],
                  pickUpTimePm: [4.3, 23.644646],
                  length: 'all',
                },
                setDayLength,
                'Day length'
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <DayLength />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ borderTop: `1px solid ${brandGray300}` }} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-interests-content"
              id="panel-interests-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.66rem'} height={'2rem'}>
                Interests
              </Typography>
              {isFilterActiveRenderClearFilter(filters.checkedInterests, [], setCheckedInterests, 'Interests')}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <InterestsFilter />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ borderTop: `1px solid ${brandGray300}` }} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-price-per-day-content"
              id="panel-price-per-day-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.66rem'} height={'2rem'}>
                Price per day
              </Typography>
              {isFilterActiveRenderClearFilter(
                filters.pricePerDay,
                { range: [0, 350], scholarshipsOnly: false },
                setPricePerDay,
                'Price per day'
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <PricePerDay />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ borderTop: `1px solid ${brandGray300}` }} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-provider-filter-content"
              id="panel-provider-filter-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.66rem'} height={'2rem'}>
                Affinity groups
              </Typography>
              {isFilterActiveRenderClearFilter(
                filters.checkedRequirements,
                {},
                setCheckedRequirements,
                'Affinity groups'
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <AudienceFilter />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ borderTop: `1px solid ${brandGray300}` }} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-provider-filter-content"
              id="panel-provider-filter-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.66rem'} height={'2rem'}>
                Provider
              </Typography>
              {isFilterActiveRenderClearFilter(filters.selectedProviders, [], setProviders, 'Provider')}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <ProviderFilter />
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} sx={{ borderTop: `1px solid ${brandGray300}` }} disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-cities-content"
              id="panel-cities-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.66rem'} height={'2rem'}>
                Cities
              </Typography>
              {isFilterActiveRenderClearFilter(
                filters.cities,
                { checkedCities: [], includeBussing: false },
                setCities,
                'Cities'
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <CitiesFilter />
            </AccordionDetails>
          </Accordion>
          {/*<Accordion elevation={0} sx={{ borderTop: `1px solid ${brandGray300}` }} disableGutters>*/}
          {/*  <AccordionSummary*/}
          {/*    expandIcon={<ExpandMoreIcon />}*/}
          {/*    aria-controls="panel-registration-status-content"*/}
          {/*    id="panel-registration-status-header"*/}
          {/*    sx={{ px: 0 }}*/}
          {/*  >*/}
          {/*    <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'1rem'} height={'2rem'}>*/}
          {/*      Registration status*/}
          {/*    </Typography>*/}
          {/*    {isFilterActiveRenderClearFilter(*/}
          {/*      filters.registrationStatus,*/}
          {/*      {*/}
          {/*        open: false,*/}
          {/*        closed: false,*/}
          {/*        full: false,*/}
          {/*        notYetOpen: false,*/}
          {/*      },*/}
          {/*      setRegistrationStatus,*/}
          {/*      'Registration status'*/}
          {/*    )}*/}
          {/*  </AccordionSummary>*/}
          {/*  <AccordionDetails sx={{ px: 0 }}>*/}
          {/*    <RegistrationStatus />*/}
          {/*  </AccordionDetails>*/}
          {/*</Accordion>*/}

          <Accordion
            elevation={0}
            sx={{ borderTop: `1px solid ${brandGray300}` }}
            disableGutters
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-additional-options-filter-content"
              id="panel-additional-options-filter-header"
              sx={{ px: 0 }}
            >
              <Typography fontWeight={600} display={'flex'} alignItems={'center'} mr={'0.66rem'} height={'2rem'}>
                Additional options
              </Typography>
              {isFilterActiveRenderClearFilter(
                filters.additionalOptions,
                { hideTentative: false, showOnlyLunchIncluded: false },
                setAdditionalOptions,
                'Additional options'
              )}
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0 }}>
              <AdditionalOptions />
            </AccordionDetails>
          </Accordion>
          <Hidden lgDown>
            <Box my={1}>
              <Divider />
            </Box>
          </Hidden>
        </Box>
        <Box
          position={{
            xs: 'sticky',
            lg: 'static',
          }}
          bottom="0"
          px={{
            xs: 2,
            lg: 4,
          }}
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: [theme.shadows[10], theme.shadows[10], theme.shadows[10], 'none'],
            zIndex: 1,
          }}
        >
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            py={{
              xs: 2,
              lg: 1,
            }}
          >
            <Hidden lgDown>
              <Box py={1}>{campListFiltered.length} Results</Box>
              <Button color="inherit" onClick={handleResetFilters} startIcon={<RefreshOutlinedIcon />} sx={{ mr: 1 }}>
                Clear All
              </Button>
            </Hidden>
            <Hidden lgUp>
              <Button color="inherit" onClick={handleResetFilters} startIcon={<RefreshOutlinedIcon />} sx={{ mr: 1 }}>
                Clear Filters
              </Button>
              <Button
                color={campListFiltered.length <= 0 ? 'error' : 'inherit'}
                variant="outlined"
                onClick={handleFilterDrawerToggle}
                style={{ cursor: campListFiltered.length <= 0 ? 'no-drop' : 'pointer' }}
              >
                Show {campListFiltered.length} Results
              </Button>
            </Hidden>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
