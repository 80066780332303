import { api } from '@libs/api'
import { Alert, Box, Snackbar, useMediaQuery } from '@mui/material'
import { Text } from '@stories/Typography/Text/Text'
import mixpanel from 'mixpanel-browser'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { AuthLayoutWrapper } from '../Layouts/style'

const EmailVerify = () => {
  const { key } = useParams()
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')

  if (!key) {
    return null
  }

  useEffect(() => {
    mixpanel.track_pageview({ page: 'EmailVerify' })

    api
      .emailVerify(key)
      .then(() => {
        window.location.href = '/'
      })
      .catch((error) => {
        const response = error.response.data

        const keys = Object.keys(response)
        // @ts-ignore
        for (const [index, key] of keys.entries()) {
          if (index >= 0) {
            setIsSnackbarOpen(true)
            setAlertMessage(response[key])
            break
          }
        }
      })
  }, [])

  // @ts-ignore
  return (
    <AuthLayoutWrapper>
      <Box sx={{ padding: isSmallScreen ? '3rem 1.5rem' : '0' }}>
        {alertMessage && (
          <>
            <Text variant={'h1'} fontSize="32px" fontWeight="semibold" textAlign="center">
              There was an unexpected error during the email verification.
            </Text>
            <br />
            <Text variant={'h6'} fontSize="24px" fontWeight="semibold" textAlign="center">
              Please contact our support team to further assist you verifying your account. <br />
              <br />
              email@camperoni.com
            </Text>
          </>
        )}
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={50000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setIsSnackbarOpen(false)
        }}
      >
        <Alert severity="error">{alertMessage}</Alert>
      </Snackbar>
    </AuthLayoutWrapper>
  )
}

export default EmailVerify
