import { Icons } from '@assets/svg'
import { colors, theme } from '@config/theme'
import { dayLength } from '@decorators/day-length'
import { dateToHumanReadable, priceFormatter, timeFormatter } from '@decorators/number-formatter.ts'
import { useAppSelector } from '@store/hooks.ts'
import { Favorite } from '@stories/Icons/Heart/Favorite'
import moment from 'moment'
// import { LocationEnterDialog } from '@stories/Components/LocationEnter/LocationEnterDialog.tsx'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'

import noCampImageFallback from '../../../../assets/media/images/no-camp-image-fallback.jpg'
import { CampBadge } from '../../../../components/CampPage'
import { BadgeInterface } from '../../../../libs/helpers.ts'

const BusIcon: React.ComponentType<React.SVGProps<SVGSVGElement>> = Icons['BusIcon']

interface CampItemProps {
  image: string
  title: string
  LinkTo: string
  registration_open_date?: string
  registration_close_date?: string
  badges: BadgeInterface[]
  corporate_provider_name: string
  ages_from: number
  ages_to: number
  dates: string[]
  facility_name: string
  day_length: 'FULL' | 'HALF_AM' | 'HALF_PM' | 'OVERNIGHT'
  earliest_dropoff_time?: string
  latest_pickup_time?: string
  daily_price: string
  bussing_options?: string[] | null
  setFavCampId: () => void
}

const StyledFavorite = styled(Favorite)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`

const CampItemWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 0.625rem;
  margin-block-end: 1.5rem;

  @media ${(props) => props.theme.device['lg']} {
    button:not(.location-link) {
      display: block;
    }

    &:hover button {
      display: block;
    }
  }
`
const CampItemImage = styled.div`
  border-radius: 1rem;
  text-align: center;
  position: relative;

  img {
    width: 100%;
    max-width: 100%;
    border-radius: 0.75rem;
  }
`
const StyledBusIcon = styled(BusIcon)`
  position: absolute;
  bottom: 2.5rem;
  left: -0.65rem;
  z-index: 1;
  @media ${({ theme }) => theme.device['lg']} {
    bottom: 1rem;
  }
`
const CampBadgesWrapper = styled.div<{ $theme: any }>`
  position: relative;
  margin: 0.15rem 0 0.65rem 0;
  top: -3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${({ $theme }) => $theme.device['lg']} {
    position: static;
    display: inline-block;
    overflow-x: unset;
    white-space: pre-wrap;
    padding: 0;
  }

  span {
    margin-right: 0.25rem;
    margin-top: 0.5rem;
  }
`

const CampItemBody = styled.div<{ $theme: any }>`
  position: relative;
  margin: 0.65rem 0 0.65rem 0;
  top: -2rem;
  @media ${({ $theme }) => $theme.device['lg']} {
    position: static;
  }

  p {
    margin-top: 1rem;
    max-width: 100%;
  }

  span {
    margin-right: 0.5rem;
  }

  h2 {
    margin: 0;

    a {
      margin-bottom: 0.25rem;
      color: ${colors.brandGray900};
      text-decoration: none;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.25rem;
    }
  }

  h3 {
    margin: 0.25rem 0;
    color: ${colors.brandGray};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
`
const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  color: ${colors.brandGray};
  font-size: 0.875rem;

  li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1.5rem;

    strong {
      color: ${colors.brandGray900};
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }
    .location-link {
      color: #175cd3;
      font-weight: 600;
      cursor: pointer;
    }
  }
`

export const CampItem: React.FC<CampItemProps> = ({
  image,
  title,
  LinkTo,
  ages_from,
  ages_to,
  badges,
  registration_open_date,
  day_length,
  corporate_provider_name,
  dates,
  facility_name,
  earliest_dropoff_time,
  latest_pickup_time,
  daily_price,
  bussing_options,
  setFavCampId,
}: React.PropsWithChildren<CampItemProps>) => {
  const zipCode = useAppSelector((state) => state.campFilters.filters.distance.zipCode)
  return (
    <ThemeProvider theme={theme}>
      <CampItemWrapper>
        <CampItemImage>
          <Link to={LinkTo}>
            {bussing_options && bussing_options.length > 0 && <StyledBusIcon />}
            <img
              src={image}
              alt={title}
              loading={'lazy'}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null
                currentTarget.src = noCampImageFallback
              }}
            />
          </Link>
        </CampItemImage>
        <StyledFavorite onClick={() => setFavCampId()} />

        <CampBadgesWrapper $theme={theme}>
          {badges.map((badge, index) => (
            <CampBadge data={badge} key={index} />
          ))}
        </CampBadgesWrapper>
        <CampItemBody $theme={theme}>
          <h2>
            <Link to={LinkTo}>{title}</Link>
          </h2>

          <h3>{corporate_provider_name}</h3>

          <StyledList>
            <li>
              Ages: {ages_from}-{ages_to}
            </li>

            <li>{dayLength(`${day_length}`)}</li>

            {registration_open_date && moment(`${registration_open_date}`).isAfter() && (
              <li>Opens: {dateToHumanReadable(`${registration_open_date}`)}</li>
            )}
            {dates?.length >= 1 && dates[0] && (
              <>
                <li>Start date: {dateToHumanReadable(`${dates[0]}`)}</li>
                <li>End date: {dateToHumanReadable(`${dates[dates.length - 1]}`)}</li>
              </>
            )}
            {registration_open_date && <li>Registration Date: {dateToHumanReadable(`${registration_open_date}`)}</li>}
            <li>{facility_name}</li>
            {!zipCode && (
              <li style={{ display: 'none' }}>
                Distance:&nbsp;
                {/*<button className="location-link" onClick={() => setOpenLocationDialog(true)}>*/}
                {/*  Enter location*/}
                {/*</button>*/}
              </li>
            )}

            {earliest_dropoff_time && <li>Earliest dropoff: {timeFormatter(`${earliest_dropoff_time}`)}</li>}
            {latest_pickup_time && <li>Latest pickup: {timeFormatter(`${latest_pickup_time}`)}</li>}
            <li>
              <strong>{priceFormatter(`${daily_price}`)} /day</strong>
            </li>
          </StyledList>
        </CampItemBody>
      </CampItemWrapper>
      {/*<LocationEnterDialog open={openLocationDialog} setOpen={(e) => setOpenLocationDialog(e)} />*/}
    </ThemeProvider>
  )
}
