import { Icons } from '@assets/svg'
import { colors } from '@config/theme'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { Obfuscate } from '@south-paw/react-obfuscate-ts'
import { Text } from '@stories/Typography/Text/Text'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { addressConfig, emailAddress } from '.././config/GlobalVariables'
import TheTeamResized from '../assets/media/images/the-team-resized.jpg'

const EmailIcon = Icons['email']

const contactLinks = [
  {
    id: 1,
    title: 'Email',
    subtitle: 'Our friendly team is here to help.',
    info: emailAddress,
    icon: <EmailIcon />,
  },
]

const Contact = () => {
  useEffect(() => {
    mixpanel.track_pageview({ page: 'ContactPage' })
  }, [])

  const isSmallScreen = useMediaQuery('(max-width:600px)')
  return (
    <>
      <Helmet>
        <title>Camperoni Website - contact</title>
        <link rel="canonical" href={`https://www.camperoni.com/contact`} />
      </Helmet>

      <Box sx={{ padding: isSmallScreen ? '4rem 1.5rem' : '5rem 6rem' }}>
        <Text
          variant={'h6'}
          fontSize={isSmallScreen ? '14px' : '16px'}
          fontWeight="semibold"
          color={colors.brandGray}
          mb={'0.75rem'}
        >
          Contact us
        </Text>
        <Text
          variant={'h1'}
          fontSize={isSmallScreen ? '30px' : '48px'}
          fontFamily="Ubuntu"
          fontWeight="bold"
          color={colors.brandGray900}
          mb={'0.75rem'}
        >
          We’d love to hear from you
        </Text>
        <Grid container spacing={4} sx={{ paddingY: isSmallScreen ? '2rem' : '3rem' }}>
          <Grid item sm={12} md={6}>
            <Grid container spacing={isSmallScreen ? 4 : 8}>
              {contactLinks.map((item) => (
                <Grid item sm={12} md={12} key={item.id}>
                  <Box marginBottom={'1rem'} display={'flex'} alignContent={'space-between'}>
                    {item.icon}
                    <Text
                      variant={'h3'}
                      fontSize={isSmallScreen ? '18px' : '18px'}
                      lineHeight={isSmallScreen ? '18px' : '18px'}
                      fontWeight="semibold"
                      color={colors.brandGray900}
                      ml={'1rem'}
                    >
                      {item.subtitle}
                    </Text>
                  </Box>

                  <Text variant={'h6'} fontSize={'16px'} color={colors.brandGray} mb={'1rem'} lineHeight={'26px'}>
                    - Any technical issues or bugs you encounter
                    <br />- Camps you&apos;d like us to add
                    <br />- Cities you&apos;d like us to go to next
                    <br />- Press inquiries
                    <br />- Saying hi and rooting us on
                    <br />- Feedback and ideas for improvement
                  </Text>
                  <Text variant={'h6'} fontSize={'16px'} fontWeight="semibold" color={colors.brandBlue} mb={'1rem'}>
                    <Obfuscate email>{emailAddress}</Obfuscate>
                  </Text>
                  <Text variant={'h6'} fontSize={'16px'} color={colors.brandGray} mb={'1rem'} lineHeight={'26px'}>
                    {addressConfig[0]} <br /> {addressConfig[1]} <br /> {addressConfig[2]}
                  </Text>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item sm={12} md={6}>
            <img src={TheTeamResized} alt={'the team'} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Contact
