import { Icons } from '@assets/svg'
// import { Input } from '@stories/Theme/Input/Input.tsx'
import { colors } from '@config/theme'
import { Text } from '@stories/Typography/Text/Text.tsx'
// import { Button } from '@stories/Theme/Button/Button'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Logo from '../../assets/svg/logo.svg'

const FooterWrapper = styled.footer`
  border-top: 1px solid #d0d5dd;
  padding: 1.5rem;
  margin-top: 2.3rem;
  @media${({ theme }) => theme.device['md']} {
    padding: 2rem;
  }
  .divider {
    margin: 2rem 0;
    @media${({ theme }) => theme.device['md']} {
      margin: 1rem 0;
    }
    border-bottom: 2px solid #f6f7f9;
  }
  ul {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
`

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  @media${({ theme }) => theme.device['md']} {
    flex-direction: row;
  }

  .info-section {
    width: 100%;
    @media${({ theme }) => theme.device['md']} {
      width: 50%;
    }
    .subtitle {
      margin-top: 1rem;
      width: auto;
      @media${({ theme }) => theme.device['md']} {
        width: 360px;
      }
    }
  }

  .contact-us-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column-reverse;
    @media${({ theme }) => theme.device['md']} {
      flex-direction: row;
      width: 50%;
    }
    .links-section {
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 1.5rem 0;
      @media${({ theme }) => theme.device['md']} {
        width: 50%;
      }
      .links-container {
        width: 100%;
        @media${({ theme }) => theme.device['md']} {
          width: 50%;
        }
      }
      .social-links-container {
        width: 100%;
        @media${({ theme }) => theme.device['md']} {
          width: 50%;
        }
      }
      .head-title {
        margin-bottom: 0.8rem;
      }
      .link {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-bottom: 16px;
        .icon {
          margin-right: 0.4rem;
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .subscriber-section {
      display: flex;
      width: 100%;
      padding: 1.5rem 0;
      flex-direction: column;
      height: 180px;
      @media${({ theme }) => theme.device['md']} {
        flex-direction: row;
        width: 50%;
        height: 100px;
      }
      .input-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        @media${({ theme }) => theme.device['md']} {
          width: 70%;
        }
      }
      .button-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 0.8rem;
        margin-left: 0;
        @media${({ theme }) => theme.device['md']} {
          width: 30%;
          margin-top: 0;
          margin-left: 0.6rem;
        }
      }
    }
  }
`

const PoliciesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media${({ theme }) => theme.device['md']} {
    flex-direction: row;
  }
  .paragraph {
    width: 100%;
    @media${({ theme }) => theme.device['md']} {
      width: 70%;
    }
  }
  .terms-link {
    display: flex;

    width: 100%;
    justify-content: flex-start;
    padding: 1rem 0;
    @media${({ theme }) => theme.device['md']} {
      justify-content: flex-end;
      width: 30%;
    }
    .link {
      margin-left: 0;
      margin-right: 0.8rem;
      text-decoration: none;
      @media${({ theme }) => theme.device['md']} {
        margin-left: 0.8rem;
        margin-right: 0;
      }
    }
  }
`

const links = [
  {
    id: 1,
    name: 'Camp Search',
    src: '/camps',
  },
  {
    id: 2,
    name: 'Info for Providers',
    src: 'https://www.camperoni.com/blog?p=getting-the-word-out-about-amazing-camp-options-in-the-twin-cities',
    target: '_blank',
  },
  {
    id: 3,
    name: 'Merch Shop',
    src: 'https://camperoni.etsy.com',
    target: '_blank',
  },
  {
    id: 4,
    name: 'Blog',
    src: '/blog',
    target: '_blank',
  },
  {
    id: 5,
    name: 'Contact',
    src: '/contact',
  },
]

const socialLinks = [
  {
    id: 1,
    name: 'Twitter',
    src: 'https://twitter.com/TeamCamperoni',
    icon: Icons['twitter'],
  },
  {
    id: 2,
    name: 'Instagram',
    src: 'https://www.instagram.com/teamcamperoni/',
    icon: Icons['instagram'],
  },
  {
    id: 3,
    name: 'Facebook',
    src: 'https://www.facebook.com/TeamCamperoni',
    icon: Icons['facebook'],
  },
]

const termsLink = [
  {
    id: 1,
    name: 'Terms',
    src: '/terms',
  },
  {
    id: 2,
    name: 'Privacy',
    src: '/privacy-policy',
  },
  // {
  //   id: 3,
  //   name: 'Cookies',
  //   src: '#',
  // },
]

const Footer = () => {
  return (
    <FooterWrapper>
      <MainSection>
        <div className="info-section">
          <Link to="/" className="brand-logo">
            <img src={Logo} alt="logo" height={52} width={155} />
          </Link>
          <Text className="subtitle" variant={'p'} fontSize="16px" color={colors.brandGray}>
            {
              'At Camperoni, we do our best to keep camp information up to date, however we are not the provider of any camp and details can change frequently. Please click through to the provider website to double check any important information.'
            }
          </Text>
        </div>
        <div className="contact-us-section">
          <div className="links-section">
            <ul className="links-container">
              {links.map((item) => (
                <Text
                  key={item.id}
                  variant="li"
                  className="link"
                  fontWeight="semibold"
                  fontSize="16px"
                  color={colors.brandGray}
                >
                  <Link to={item.src} className="link" target={item.target}>
                    {item.name}
                  </Link>
                </Text>
              ))}
            </ul>
            <ul className="social-links-container">
              <Text variant="li" className="link" fontWeight="semibold" fontSize="14px" color={colors.brandGray}>
                Follow us
              </Text>
              {socialLinks.map((item) => {
                const Icon = item.icon
                return (
                  <a href={item.src} key={item.id} target="_blank" rel="noreferrer">
                    <Text
                      key={item.id}
                      variant="li"
                      className="link"
                      fontWeight="semibold"
                      fontSize="16px"
                      color={colors.brandGray}
                    >
                      <Icon className="icon" />
                      <span> {item.name}</span>
                    </Text>
                  </a>
                )
              })}
            </ul>
          </div>
          {/*<div className="subscriber-section">*/}
          {/*  <div className="input-container">*/}
          {/*    <Text mb="0.8rem">Stay up to date</Text>*/}
          {/*    <Input onChange={() => {}} rounded="small" className="input-style" placeholder="Enter your email" />*/}
          {/*  </div>*/}
          {/*  <div className="button-container">*/}
          {/*    <Button title="Subscribe" size="small" />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </MainSection>
      <div className="divider" />
      <PoliciesContainer>
        <Text variant={'p'} fontSize="14px" className="paragraph" color={colors.brandGray500}>
          © {new Date().getFullYear()} Camperoni, Inc. All rights reserved. Camperoni collects any information you
          provide about children under the age of thirteen through your use of search, filters, or your personal
          profile. Some of this information may be personally identifiable. Camperoni collects this information to help
          personalize your search results and overall experience using our site and app. We do not disclose personally
          identifiable information about children under 13 to any third parties.
        </Text>
        <ul className="terms-link">
          {termsLink.map((item) => (
            <Text variant={'li'} key={item.id} fontSize="16px" className="link" color={colors.brandGray500}>
              <Link to={item.src} className="link">
                {item.name}
              </Link>
            </Text>
          ))}
        </ul>
      </PoliciesContainer>
    </FooterWrapper>
  )
}

export default Footer
