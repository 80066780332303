import { api, apiResponse } from '@libs/api'
import { call, CallEffect, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects'

import { Provider } from '../../libs/types/Provider'
import { Purchaseoption } from '../../libs/types/Purchaseoption.ts'
import { getProviderList, setProviderList } from '../campFilters/campFiltersSlice'
import { getRequirementList, setPurchaseOptions, setRequirementList } from './campPageSlice'

function* loadProviderList(): Generator<CallEffect | PutEffect | SelectEffect, void | any> {
  const { data: providers } = (yield call(api.getProviders)) as apiResponse<Provider[]>
  const { data: purchaseOptions } = (yield call(api.getPurchaseOptions)) as apiResponse<Purchaseoption[]>

  yield put(setPurchaseOptions(purchaseOptions))
  yield put(setProviderList(providers))
}

function* loadRequirementList(): Generator<CallEffect | PutEffect | SelectEffect, void | any> {
  const { data: requirements } = (yield call(api.getRequirements)) as apiResponse<Provider[]>

  yield put(setRequirementList(requirements))
}

export default function* campPageSaga() {
  yield takeLatest(getProviderList, loadProviderList)
  yield takeLatest(getRequirementList, loadRequirementList)
}
