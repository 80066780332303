import React from 'react'
import styled from 'styled-components'

interface CloseButtonProps {
  onClick: () => void
}

const CloseButtonStyled = styled.button`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`

export const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
  return (
    <CloseButtonStyled onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path
          d="M18.166 6.63818L6.16602 18.6382M6.16602 6.63818L18.166 18.6382"
          stroke="#98A2B3"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </CloseButtonStyled>
  )
}
