import { dayLength } from '@decorators/day-length.ts'
import { findEntityById } from '@decorators/findEntityById.ts'
import { dateToHumanReadable, priceFormatter, timeFormatter } from '@decorators/number-formatter.ts'
import { registrationStatus } from '@decorators/registration-status.ts'
import { Button } from '@stories/Theme/Button/Button'
import React, { useState } from 'react'
import { FiMapPin } from 'react-icons/fi'
import { GoLinkExternal } from 'react-icons/go'
import { RxCaretDown, RxCaretUp } from 'react-icons/rx'
import styled from 'styled-components'

import Eventbus from '../../libs/eventbus.ts'
import { BadgeInterface } from '../../libs/helpers.ts'
import { Camp } from '../../libs/types/Camp'
import { Requirement } from '../../libs/types/Requirement'
import AddCalendar from './AddCalendar'
import AddToListAndShareButtons from './AddToListAndShareButtons'
import CampPageCalendar from './CampPageCalendar'

const CampDetailsWrapper = styled.section`
  .blue {
    color: ${({ theme }) => theme.colors['brand-blue']};
  }

  .disabled {
    color: ${({ theme }) => theme.colors['brand-gray']};
    cursor: default;
  }

  .row {
    display: flex;
    margin: 12px 0;

    &.no-bottom-margin {
      margin: 12px 0 0;
    }
  }

  span {
    align-items: center;
    flex: 1;
  }

  .bold {
    display: flex;
    font-weight: bold;
  }

  .calendar {
    .gray {
      color: ${({ theme }) => theme.colors['brand-gray-400']};
    }

    span {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .margin-top {
    margin-top: 10px;
  }

  .link {
    align-items: center;
    display: flex;

    span {
      flex: 0 2 auto;
    }
  }

  .link-section {
    cursor: pointer;
  }

  .align-items-start {
    align-items: flex-start;
  }

  .margin-bottom {
    margin-bottom: 10px;
  }

  .read-more {
    div {
      cursor: pointer;
    }

    div,
    svg {
      align-items: center;
      display: inline-flex;
      font-weight: bold;
    }

    div span {
      align-items: center;
      display: flex;
    }

    svg {
      font-size: 22px;
    }
  }

  .refund-policy {
    margin-bottom: 10px;
    max-height: 60px;
    overflow-y: hidden;
    text-align: justify;
  }

  .open {
    .refund-policy {
      max-height: unset;
    }
  }
  .hidden {
    display: none;
  }
`

const CampDetails = (props: {
  data: Camp
  badges: BadgeInterface[]
  refundPolicy: string
  requirements: Requirement[]
  scholarshipInfoUrl: string
  onAddToFavoriteButtonClick: () => void
}) => {
  const data = props.data
  const requirements = props.requirements
  const scholarshipInfoUrl = props.scholarshipInfoUrl
  const refundPolicy = props.refundPolicy || ''
  const [isRefundPolicyOpen, setIsRefundPolicyOpen] = useState(false)

  const groups = React.useMemo(() => {
    const groups: string[] = []
    data.requirements?.forEach((id) => {
      const res = findEntityById(id, requirements)
      if (res) {
        groups.push(res.name)
      }
    })

    return groups.join(', ')
  }, [data.requirements])

  const calendarData = React.useMemo(() => {
    const name = data.name
    const id = data.id
    const location = `${data.facility_name} (${data.address})`
    const providerId = data.corporate_provider
    let startDate,
      endDate,
      startTime,
      endTime,
      registrationOpenDate,
      registrationOpenTime,
      registrationCloseDate,
      registrationCloseTime,
      registrationLink = ''
    if (data.dates && data.dates.length > 0) {
      startDate = data.dates[0]
      endDate = data.dates[data.dates.length - 1]
    }
    if (data.start_time) {
      startTime = data.start_time
    }
    if (data.end_time) {
      endTime = data.end_time
    }
    if (data.registration_open_date) {
      registrationOpenDate = data.registration_open_date
    }
    if (data.registration_open_time) {
      registrationOpenTime = data.registration_open_time
    }
    if (data.registration_close_date) {
      registrationCloseDate = data.registration_close_date
    }
    if (data.registration_close_time) {
      registrationCloseTime = data.registration_close_time
    }
    if (data.registration_link) {
      registrationLink = data.registration_link
    }

    return {
      id,
      providerId,
      name,
      location,
      startDate,
      endDate,
      startTime,
      endTime,
      registrationOpenDate,
      registrationOpenTime,
      registrationCloseDate,
      registrationCloseTime,
      registrationLink,
    }
  }, [data.dates, data.start_time, data.end_time])

  const purchaseOptionsDecorated = props.data?.purchase_options_decorated || []

  return (
    <CampDetailsWrapper>
      {/*{badges && (*/}
      {/*  <div className="row">*/}
      {/*    <span className="bold">Interests</span>*/}
      {/*    <span>*/}
      {/*      {badges.length > 0*/}
      {/*        ? badges.map((item, index) => (*/}
      {/*          <span key={index}>*/}
      {/*              {item.name}*/}
      {/*            {index < badges.length - 1 && ', '}*/}
      {/*            </span>*/}
      {/*        ))*/}
      {/*        : '-'}*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*)}*/}
      <div className="row">
        <span className="bold">Ages</span>
        <span>{(data.ages_from && data.ages_to && `${data.ages_from}-${data.ages_to}`) || '-'}</span>
      </div>
      <div className="row">
        <span className="bold">Affinity Groups</span>
        <span>{groups || '-'}</span>
      </div>
      <div className="row">
        <span className="align-items-start bold">Dates</span>
        <span>
          <div className="margin-bottom">
            {(data.dates &&
              data.dates.map((date, index: number) => {
                return (
                  <span key={index}>
                    {dateToHumanReadable(date)} <br />
                  </span>
                )
              })) ||
              '-'}
          </div>
          {(data.dates && <CampPageCalendar dates={data.dates} />) || ''}
        </span>
      </div>
      <div className="row">
        <span className="bold">Day length</span>
        <span>{dayLength(data.day_length)}</span>
      </div>
      <div className="row">
        <span className="bold">Start time</span>
        <span>{(data.start_time && timeFormatter(data.start_time)) || '-'}</span>
      </div>
      <div className="row">
        <span className="bold">End time</span>
        <span>{(data.end_time && timeFormatter(data.end_time)) || '-'}</span>
      </div>
      <div className="row">
        <span className="bold">Drop-off begins</span>
        <span>{(data.earliest_dropoff_time && timeFormatter(data.earliest_dropoff_time)) || '-'}</span>
      </div>
      <div className="row">
        <span className="bold">Pick-up ends:</span>
        <span>{(data.latest_pickup_time && timeFormatter(data.latest_pickup_time)) || '-'}</span>
      </div>
      <div className="row">
        <span className="bold">Extended day start:</span>
        <span>
          {(data.extended_earliest_dropoff_time && timeFormatter(data.extended_earliest_dropoff_time)) || '-'}
        </span>
      </div>
      <div className="row">
        <span className="bold">Extended day end:</span>
        <span>{(data.extended_latest_pickup_time && timeFormatter(data.extended_latest_pickup_time)) || '-'}</span>
      </div>
      <div className="row">
        <span className="bold">Facility</span>
        <span>{data.facility_name || '-'}</span>
      </div>

      <div className="row">
        <span className="align-items-start bold">Location</span>
        <span>
          <div>{data.address || '-'}</div>
          <div className="margin-top hide-section">
            <Button
              className="map-button"
              onClick={() => {}}
              icon={<FiMapPin />}
              size="large"
              title="See on map"
              variant="secondary"
            />
          </div>
        </span>
      </div>

      {data.bussing_options && data.bussing_options.length > 0 && (
        <div className="row">
          <span className="align-items-start bold">Bussing Options</span>
          <span>
            <div className="margin-bottom">
              {data.bussing_options.map((bussingOption: string, index: number) => (
                <span key={index}>
                  {bussingOption} <br />
                </span>
              ))}
            </div>
          </span>
        </div>
      )}

      <div className="row link-section hide-section">
        <span className="bold">Accessibility</span>
        <span className="blue link">
          <span>Accessibility policy</span>
          <Button
            className="blue"
            onClick={() => {}}
            icon={<GoLinkExternal />}
            size="small"
            style={{ border: 'none' }}
            title=""
            variant="secondary"
          />
        </span>
      </div>

      <div className="row">
        <span className="bold">Purchase options</span>
        <span>
          {purchaseOptionsDecorated &&
            purchaseOptionsDecorated.map((item, index) => (
              <span key={index}>
                {item.name}
                {index < purchaseOptionsDecorated.length - 1 && ', '}
              </span>
            ))}
        </span>
      </div>

      <div className="row">
        <span className="bold">Price per Day</span>
        <span>{(data.daily_price && priceFormatter(`${data.daily_price}`)) || '-'}</span>
      </div>

      <div className="row link-section">
        <span className="bold">Scholarship</span>
        <span
          className={scholarshipInfoUrl ? 'link blue' : 'link disabled'}
          onClick={() => {
            if (scholarshipInfoUrl) {
              window.open(scholarshipInfoUrl, '_blank')
            }
          }}
        >
          {scholarshipInfoUrl ? (
            <>
              <span>See scholarship program</span>
              <Button
                className="blue"
                icon={<GoLinkExternal />}
                size="small"
                style={{ border: 'none' }}
                title=""
                variant="secondary"
              />
            </>
          ) : (
            'Unknown'
          )}
        </span>
      </div>
      <div className="row no-bottom-margin hidden">
        <span className="align-items-start bold">Registrations</span>
        <span>
          <div className="margin-bottom">
            {data.registration_status === 'OPEN' &&
            data.registration_open_date &&
            data.registration_open_time &&
            data.registration_close_date &&
            data.registration_close_time
              ? `${data.registration_status} on ${data.registration_open_date} ${data.registration_open_time} - ${data.registration_close_date} ${data.registration_close_time}`
              : registrationStatus(data.registration_status) || '-'}
          </div>
          <div className="margin-bottom">
            {data.registration_status === 'NOT YET OPEN' && data.registration_open_date && (
              <AddCalendar data={calendarData} />
            )}
          </div>
        </span>
      </div>
      {data.registration_open_date ? (
        <div className="row">
          <span className="bold">Registration Date</span>
          <span>{dateToHumanReadable(data.registration_open_date)}</span>
        </div>
      ) : (
        <div className="row">
          <span className="bold">Registration Date</span>
          <span>Unknown</span>
        </div>
      )}
      {data.registration_link && (
        <>
          <div className="row link-section">
            <span className="bold">Registration Link</span>
            <span
              className={data.registration_link ? 'link blue' : 'link disabled'}
              onClick={() => {
                if (data.registration_link) {
                  window.open(data.registration_link, '_blank')
                  Eventbus.trigger(Eventbus.MIXPANEL_CTA_CLICKED, {
                    value: 'Clicked Registration Link',
                    properties: {
                      'Provider ID': data.corporate_provider,
                      'Camp ID': data.id,
                    },
                  })
                }
              }}
            >
              {data.registration_link ? (
                <>
                  <span>Registration Link</span>
                  <Button
                    className="blue"
                    icon={<GoLinkExternal />}
                    size="small"
                    style={{ border: 'none' }}
                    title=""
                    variant="secondary"
                  />
                </>
              ) : (
                'Unknown'
              )}
            </span>
          </div>
        </>
      )}

      <div className={refundPolicy.length === 0 ? 'row hide-section' : 'row'}>
        <span className="align-items-start bold">Refund Policy</span>
        <span className={isRefundPolicyOpen ? 'open' : ''}>
          <div className="refund-policy">{refundPolicy}</div>
          <div className="read-more">
            <div
              className={refundPolicy.length === 0 || refundPolicy.length <= 100 ? 'hide-section' : ''}
              onClick={() => {
                setIsRefundPolicyOpen(!isRefundPolicyOpen)
              }}
            >
              {isRefundPolicyOpen ? (
                <span>
                  Collapse <RxCaretUp />
                </span>
              ) : (
                <span>
                  Read more <RxCaretDown />
                </span>
              )}
            </div>
          </div>
        </span>
      </div>
      <div>
        <AddToListAndShareButtons size="large" withShareButton={true} onClick={props.onAddToFavoriteButtonClick} />
      </div>
    </CampDetailsWrapper>
  )
}

export default CampDetails
