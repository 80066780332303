import { setPricePerDayRange, setPricePerDayScholarshipsOnly } from '@store/campFilters/campFiltersSlice.ts'
import { useAppDispatch } from '@store/hooks'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const usePricePerDayFilters = (): void => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()

  const filter_price_per_day = searchParams.get('filter_price_per_day')
  const filter_scholarships_only = searchParams.get('filter_scholarships_only')

  useEffect(() => {
    if (filter_price_per_day) {
      try {
        const filterPricePerDayRange = JSON.parse(filter_price_per_day) || []
        if (Array.isArray(filterPricePerDayRange)) {
          dispatch(setPricePerDayRange(filterPricePerDayRange))
          const params = new URLSearchParams(searchParams)
          params.delete('filter_price_per_day')
          const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
          window.history.replaceState(null, '', url)
        }
      } catch (e) {
        console.warn('tried to set price range but failed')
      }
    }
  }, [filter_price_per_day])

  useEffect(() => {
    if (filter_scholarships_only) {
      dispatch(setPricePerDayScholarshipsOnly(filter_scholarships_only === 'true'))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_scholarships_only')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_scholarships_only])
}
