import { colors } from '@config/theme'
import { decorateEntityIds } from '@decorators/decorateEntityIds'
import { api } from '@libs/api'
import { Grid, Stack } from '@mui/material'
import { Skeleton } from '@mui/material'
import { AddToListDrawer } from '@stories/Components/AddtoList/AddToListDrawer'
import { Text } from '@stories/Typography/Text/Text.tsx'
import { AxiosError } from 'axios'
import mixpanel from 'mixpanel-browser'
import React, { lazy, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { AddToListAndShareButtons, CampBadge, CampDetails, CampImage } from '../components/CampPage'
import { getBadgesFromInterest } from '../libs/helpers'
import { Camp } from '../libs/types/Camp'
import { Purchaseoption } from '../libs/types/Purchaseoption'
import { useAppSelector } from '../store/hooks'

const NotFoundCamp = lazy(() => import('./NotFoundCamp.tsx'))

const CampPageWrapper = styled.section`
  display: flex;

  .hide-section {
    display: none !important;
  }

  @media${({ theme }) => theme.device['md']} {
    padding: 36px;
  }
`

const CampPageImageDesktopWrapper = styled.section`
  display: none;

  @media${({ theme }) => theme.device['md']} {
    display: flex;
    margin-right: 55px;
    width: 50%;
  }
`

const CampPageContent = styled.section`
  display: flex;
  padding: 20px;
  width: 100%;

  @media${({ theme }) => theme.device['md']} {
    display: flex;
    width: 50%;
  }
`

const CampPageContentColumn = styled.section`
  display: flex;
  flex-direction: column;

  .camp-page-description {
    text-align: justify;
    white-space: pre-line;
    word-wrap: break-word;
  }
`

const CampPageBadgesWrapper = styled.section`
  display: none;
  margin-top: 189px;

  .camp-badge {
    margin: 0 10px 10px 0;
  }

  @media${({ theme }) => theme.device['md']} {
    display: flex;
    flex-wrap: wrap;
  }
`

const CampPageTitle = styled(Text)`
  font-size: 2.4rem;
  margin-top: 48px;

  @media${({ theme }) => theme.device['md']} {
    margin-top: 24px;
  }
`

const CampPageSubtitleWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  margin: 24px 0 40px;
  width: 100%;
`

const CampPageSubtitle = styled(Text)`
  font-size: 1.28rem;
  width: 100%;
`

const CampPageAddToListAndShareButtonsWrapper = styled.section`
  display: none;

  @media${({ theme }) => theme.device['md']} {
    display: flex;
    margin: 24px 0 41px;
  }
`

const CampPageAddListMobileWrapper = styled.section`
  display: flex;
  font-size: 1rem;
  justify-content: flex-end;
  width: 100%;
  section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  @media${({ theme }) => theme.device['md']} {
    display: none;
  }
`

const CampPageMobileWrapper = styled.section`
  display: flex;
  margin-bottom: 40px;
  position: relative;

  .camp-badge {
    margin: 0 5px 0 0;
  }

  @media${({ theme }) => theme.device['md']} {
    display: none;
  }
`

const CampPageBadgesMobileWrapper = styled.section`
  position: absolute;
  top: 10px;
  width: 100%;
  z-index: 1;
`

const CampPageBadgesMobile = styled.section`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;

  span {
    font-size: 1.2rem;
    min-width: max-content;
  }
`

const CampPageBadgesMobileNoWrapWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1280px) {
    flex-wrap: wrap;
  }
`

const CampPageSkeleton = () => {
  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2 }} padding={'36px'} rowSpacing={2} mt={6}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Skeleton variant="rounded" width={'100%'} sx={{ height: { sm: 200, md: 400 } }} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: { sm: 0, md: 10 } }}>
        <Stack spacing={2}>
          <Skeleton variant="rounded" width={'100%'} sx={{ height: { sm: 120, md: 250 } }} />
          <Skeleton variant="rounded" width={'100%'} sx={{ height: { sm: 60, md: 100 } }} />
          <Skeleton variant="rounded" width={'100%'} sx={{ height: { sm: 30, md: 500 } }} />
        </Stack>
      </Grid>
    </Grid>
  )
}

const CampPage: React.FC = () => {
  const { campId } = useParams()

  const interestList = useAppSelector((state) => state.campFilters.interestList)
  const providerList = useAppSelector((state) => state.campFilters.providerList)
  const requirementList = useAppSelector((state) => state.campPage.requirementList)

  const [favCamId, setFavCampId] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [CampNotFound, setCampNotFound] = useState(false)
  const [data, setData] = useState({} as Camp)

  React.useEffect(() => {
    mixpanel.track_pageview({ page: 'CampPage' })
  }, [])

  React.useEffect(() => {
    setIsLoading(true)
    api
      .getCampWithId(campId)
      .then((response) => response.data)
      .then((camp: Camp) => {
        camp.bussing_options = []
        api
          .getPurchaseOptions()
          .then((purchaseOptions) => {
            if (purchaseOptions.status === 200) {
              camp.purchase_options_decorated =
                decorateEntityIds<Purchaseoption>(camp.purchase_options, purchaseOptions.data) || []
            }
          })
          .then(() => {
            api
              .getFacilities()
              .then((facilities) => {
                // @ts-ignore
                camp.facility_name = facilities[camp.facility]?.name || ''
                // @ts-ignore
                return facilities[camp.facility]?.address || 0
              })
              .then((facilityAddressId) => {
                api.getAddresses().then((addresses) => {
                  camp.address = addresses[facilityAddressId]
                  api.getBussingAddresses().then((bussingAddresses) => {
                    camp.bussing_addresses.forEach((bussingAddressId) => {
                      const bussingAddress = bussingAddresses[bussingAddressId]
                      const addressId = bussingAddress?.address
                      if (addressId) {
                        const address = addresses[addressId] || ''
                        if (address) {
                          camp.bussing_options.push(`${bussingAddress.prefix}${address}${bussingAddress.suffix}`)
                        }
                      }
                    })
                  })
                })
              })
          })
          .then(() => {
            setData(camp)
          })

        setIsLoading(false)
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status >= 400 && error.response.status < 600) {
          setIsLoading(false)
          setCampNotFound(true)
        }
      })
  }, [campId])

  const badges = React.useMemo(() => {
    if (!data?.interests) {
      return []
    }

    return getBadgesFromInterest(interestList, data?.interests || [])
  }, [data?.interests, interestList])

  const providerName: string = React.useMemo(() => {
    if (!data?.corporate_provider) {
      return ''
    }

    const corporateProvider = data?.corporate_provider
    const id = corporateProvider ? [corporateProvider] : []
    const provider = decorateEntityIds(id, providerList) || []
    if (provider.length === 0) {
      return ''
    }

    return provider[0].name || ''
  }, [data?.corporate_provider, providerList])

  const refundPolicy: string = React.useMemo(() => {
    if (!data?.corporate_provider) {
      return ''
    }

    const id = data.corporate_provider
    const provider = decorateEntityIds([id], providerList) || []
    if (provider.length === 0) {
      return ''
    }

    return provider[0].refund_policy || ''
  }, [data?.corporate_provider, providerList])

  const scholarshipInfoUrl = React.useMemo(() => {
    if (!data?.corporate_provider) {
      return ''
    }

    const id = data.corporate_provider
    const provider = decorateEntityIds([id], providerList) || []
    if (provider.length === 0) {
      return ''
    }

    return provider[0].scholarship_info_url || ''
  }, [data?.corporate_provider, providerList])

  return (
    <>
      {isLoading ? (
        <CampPageSkeleton />
      ) : CampNotFound ? (
        <NotFoundCamp />
      ) : (
        <>
          <Helmet>
            <title>Camperoni Website - {data?.name || ''}</title>
            <link rel="canonical" href={`https://www.camperoni.com/camps/${campId}`} />
          </Helmet>

          <CampPageWrapper>
            <CampPageImageDesktopWrapper>
              <CampImage imageUrl={data?.image_url || ''} />
            </CampPageImageDesktopWrapper>
            <CampPageContent>
              <CampPageContentColumn>
                <CampPageBadgesWrapper>
                  {badges.map((o, i) => (
                    <span className="camp-badge" key={`camp_badge_span_${i}`}>
                      <CampBadge data={o} key={`camp_badge_${i}`} />
                    </span>
                  ))}
                </CampPageBadgesWrapper>
                <CampPageTitle variant={'h1'} fontWeight="bold" color={colors.brandGray700}>
                  {data?.name || ''}
                </CampPageTitle>
                <CampPageSubtitleWrapper>
                  <CampPageSubtitle variant={'h3'} fontWeight="bold" color={colors.brandBlue}>
                    <a
                      href={`/camps?filter_selected_providers=[${data?.corporate_provider}]`}
                      target={'_blank'}
                      rel="noreferrer"
                    >
                      {providerName}
                    </a>
                  </CampPageSubtitle>
                  <CampPageAddListMobileWrapper>
                    <AddToListAndShareButtons
                      size="small"
                      withShareButton
                      onClick={() => setFavCampId(parseInt(campId as string))}
                    />
                  </CampPageAddListMobileWrapper>
                </CampPageSubtitleWrapper>
                <CampPageAddToListAndShareButtonsWrapper>
                  <AddToListAndShareButtons
                    size="large"
                    withShareButton={true}
                    onClick={() => setFavCampId(parseInt(campId as string))}
                  />
                </CampPageAddToListAndShareButtonsWrapper>
                <CampPageMobileWrapper>
                  <CampPageBadgesMobileWrapper>
                    <CampPageBadgesMobile>
                      <CampPageBadgesMobileNoWrapWrapper>
                        {badges.length > 0 &&
                          badges.map((o, i) => (
                            <span className="camp-badge" key={i}>
                              <CampBadge data={o} />
                            </span>
                          ))}
                      </CampPageBadgesMobileNoWrapWrapper>
                      {/*<span>*/}
                      {/*  <Button*/}
                      {/*    className="share-button"*/}
                      {/*    onClick={() => {}}*/}
                      {/*    icon={<RxShare1 />}*/}
                      {/*    rounded={true}*/}
                      {/*    size="small"*/}
                      {/*    title=""*/}
                      {/*    variant="secondary"*/}
                      {/*  />*/}
                      {/*</span>*/}
                    </CampPageBadgesMobile>
                  </CampPageBadgesMobileWrapper>
                  <CampImage imageUrl={data?.image_url || ''} />
                </CampPageMobileWrapper>
                <Text
                  variant={'div'}
                  className="camp-page-description"
                  fontSize="18px"
                  lineHeight={'28px'}
                  fontWeight="regular"
                  color={colors.brandGray700}
                  mb={'0.75rem'}
                >
                  {data?.description || ''}
                </Text>
                <CampDetails
                  data={data}
                  badges={badges}
                  refundPolicy={refundPolicy}
                  requirements={requirementList}
                  scholarshipInfoUrl={scholarshipInfoUrl}
                  onAddToFavoriteButtonClick={() => setFavCampId(parseInt(campId as string, 10))}
                />
              </CampPageContentColumn>
            </CampPageContent>
          </CampPageWrapper>

          <AddToListDrawer visible={favCamId} onClose={() => setFavCampId(0)} />
        </>
      )}
    </>
  )
}

export default CampPage
