import { SpacingProps, theme } from '@config/theme'
import { api } from '@libs/api'
import { Button } from '@stories/Theme/Button/Button'
import { Logo } from '@stories/Theme/Logo/Logo.tsx'
import { FC, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-awesome-styled-grid'
import { AiOutlineMenu } from 'react-icons/ai'
import { RxCross1, RxHeart } from 'react-icons/rx'
import { Link, useNavigate } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components'

import { navListDataProps } from '../../libs/types/Nav'
import { SideNavWrapper } from './style'

const StyledList = styled.ul<SpacingProps>`
  padding: 16px;
  font-weight: 600;

  li {
    margin-bottom: 1.5rem;
  }
`

const SideNav: FC<{ navListData: navListDataProps[] }> = ({ navListData }) => {
  const navigator = useNavigate()

  const [navOpen, setNavOpen] = useState<boolean>(false)

  let isLoggedIn = api.isUserLoggedIn()

  useEffect(() => {
    isLoggedIn = api.isUserLoggedIn()
  }, [isLoggedIn])

  const logoutHandler = () => {
    api.logout()

    const nonPrivatePages = !['/settings', '/favorites', '/camps'].includes(location.pathname)
    if (nonPrivatePages) {
      return window.location.reload()
    }
    return navigator('/login')
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="right-part-mobile">
        {isLoggedIn && (
          <Link to="/favorites">
            <Button icon={<RxHeart fontSize="1rem" />} size="small" variant="secondary" rounded={true} mx="0.4rem" />
          </Link>
        )}
        <Button
          icon={<AiOutlineMenu fontSize="1rem" />}
          size="small"
          variant="secondary"
          rounded={true}
          onClick={() => setNavOpen(true)}
        />
      </div>
      <SideNavWrapper $navOpen={navOpen}>
        <div className="nav-top">
          <Logo />
          <button type="button" onClick={() => setNavOpen(!navOpen)}>
            <RxCross1 />
          </button>
        </div>
        <StyledList $pt={4}>
          {navListData.map((navListDataItem: navListDataProps) => (
            <li key={navListDataItem.id + 1}>
              <Link to={navListDataItem.path} onClick={() => setNavOpen(!navOpen)} target={navListDataItem.target}>
                {navListDataItem.title}
              </Link>
            </li>
          ))}
        </StyledList>

        <hr style={{ border: '1px solid #D0D5DD' }} />
        <Container>
          <Row>
            <Col>
              <StyledList>
                <li>
                  <Link to={'/contact'} onClick={() => setNavOpen(!navOpen)}>
                    Contact
                  </Link>
                </li>
                {/*<li>*/}
                {/*  <Link to={'/feedback'} onClick={() => setNavOpen(!navOpen)}>Feedback</Link>*/}
                {/*</li>*/}
              </StyledList>
            </Col>
            <Col>
              <StyledList>
                {/*<li>*/}
                {/*  <Link to={'/policy'} onClick={() => setNavOpen(!navOpen)}>Policy</Link>*/}
                {/*</li>*/}
                <li>
                  <Link to={'/terms'} onClick={() => setNavOpen(!navOpen)}>
                    Terms
                  </Link>
                </li>
              </StyledList>
            </Col>
          </Row>
          <Row>
            <Col>
              {!isLoggedIn ? (
                <>
                  <Link to="/register">
                    <Button title={'Sign up'} variant={'primary'} my="0.5rem" fullWidthOnSmallScreen />
                  </Link>
                  <Link to="/login">
                    {' '}
                    <Button title={'Login'} variant={'secondary'} fullWidthOnSmallScreen />
                  </Link>
                </>
              ) : (
                <>
                  <Link to={'/settings'} onClick={() => setNavOpen(!navOpen)}>
                    <Button
                      title={'Settings'}
                      variant={'secondary'}
                      my="0.5rem"
                      onClick={() => {}}
                      fullWidthOnSmallScreen
                    />
                  </Link>
                  <Button
                    title={'Logout'}
                    variant={'primary'}
                    my="0.5rem"
                    onClick={logoutHandler}
                    fullWidthOnSmallScreen
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </SideNavWrapper>
    </ThemeProvider>
  )
}

export default SideNav
