import { colors } from '@config/theme'
import { api } from '@libs/api'
import { Alert, Box, Snackbar, useMediaQuery } from '@mui/material'
import { Button } from '@stories/Theme/Button/Button'
import { Input } from '@stories/Theme/Input/Input'
import { Text } from '@stories/Typography/Text/Text'
import mixpanel from 'mixpanel-browser'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { AuthLayoutWrapper } from '../Layouts/style'

const ResetPassword = () => {
  useEffect(() => {
    mixpanel.track_pageview({ page: 'ResetPassword' })
  }, [])

  const { uid, token } = useParams()
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [isPosting, setIsPosting] = useState<boolean>(false)
  const [passwordAtLeastEightCharacters, setPasswordAtLeastEightCharacters] = useState<boolean>(false)
  const [passwordAtLeastOneSpecialChar, setPasswordAtLeastOneSpecialChar] = useState<boolean>(false)
  const [isPasswordDisabled, setIsPasswordDisabled] = useState<boolean>(true)
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false)
  const [passwordsState, setPasswordsState] = useState({
    new_password1: '',
    new_password2: '',
  })

  //eslint-disable-next-line
  const specialCharactersFormat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

  const validatePasswordRules = (value: string): boolean => {
    setPasswordAtLeastEightCharacters(value.length >= 8)
    setPasswordAtLeastOneSpecialChar(specialCharactersFormat.test(value) && value.length > 0)
    const result = !(value.length >= 8 && specialCharactersFormat.test(value))
    setIsPasswordDisabled(result)
    return result
  }
  const validatePasswordHandler = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    const passwordsStateUpdate: any = structuredClone(passwordsState)
    passwordsStateUpdate[name] = value
    setPasswordsMatch(passwordsStateUpdate['new_password1'] === passwordsStateUpdate['new_password2'])
    setPasswordsState(passwordsStateUpdate)
    validatePasswordRules(value)
  }

  const resetPasswordForm = (event: React.FormEvent<HTMLFormElement>) => {
    setIsPosting(true)
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    const { new_password1, new_password2 } = Object.fromEntries(formData.entries()) as {
      new_password1: string
      new_password2: string
    }
    api
      .resetPasswordConfirm(uid, token, new_password1, new_password2)
      .then(() => {
        setIsSnackbarOpen(true)
        setAlertMessage('Your Password has been successfully changed')
      })
      .catch((error) => {
        const response = error.response.data

        const keys = Object.keys(response)
        // @ts-ignore
        for (const [index, key] of keys.entries()) {
          if (index >= 0) {
            setIsSnackbarOpen(true)
            setAlertMessage(response[key])
            break
          }
        }
      })
      .finally(() => setIsPosting(false))
  }

  return (
    <AuthLayoutWrapper>
      <Box sx={{ padding: isSmallScreen ? '3rem 1.5rem' : '5rem 6rem' }}>
        <Text
          variant={'h1'}
          fontFamily="Ubuntu"
          fontSize={isSmallScreen ? '30px' : '48px'}
          fontWeight="bold"
          color={colors.brandGray900}
          mb={isSmallScreen ? '2rem' : '4rem'}
        >
          Reset password
        </Text>
        <form id={'reset-password-form'} onSubmit={resetPasswordForm}>
          <Box
            display="flex"
            alignItems={!isSmallScreen ? 'center' : ''}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{
              paddingBottom: '1rem',
              borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Text
              style={{ width: isSmallScreen ? 'auto' : '280px' }}
              variant={'label'}
              fontSize={'14px'}
              fontWeight="semibold"
              color={colors.brandGray700}
              mb={isSmallScreen ? '0.75rem' : ''}
            >
              New password
            </Text>
            <Input
              type="password"
              placeholder="*****"
              style={{ width: isSmallScreen ? '100%' : '484px' }}
              onChange={validatePasswordHandler}
              name={'new_password1'}
              disabled={isPosting}
            />
          </Box>
          <Box
            display="flex"
            alignItems={!isSmallScreen ? 'center' : ''}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{
              paddingBottom: '1rem',
              borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Text
              style={{ width: isSmallScreen ? 'auto' : '280px' }}
              variant={'label'}
              fontSize={'14px'}
              fontWeight="semibold"
              color={colors.brandGray700}
              mb={isSmallScreen ? '0.75rem' : ''}
            >
              Re-enter new password
            </Text>
            <Input
              type="password"
              placeholder="*****"
              style={{ width: isSmallScreen ? '100%' : '484px' }}
              onChange={validatePasswordHandler}
              name={'new_password2'}
              disabled={isPosting}
            />
          </Box>
          <div className="auth-content">
            <div className="password-checker">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <rect
                  y="0.027771"
                  width="20"
                  height="20"
                  rx="10"
                  fill={passwordAtLeastEightCharacters ? colors.brandGreen : colors.brandGray300}
                />
                <path
                  d="M6.25 10.0278L8.75 12.5278L13.75 7.52777"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Must be at least 8 characters</span>
            </div>
            <div className="password-checker">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <rect
                  y="0.027771"
                  width="20"
                  height="20"
                  rx="10"
                  fill={passwordAtLeastOneSpecialChar ? colors.brandGreen : colors.brandGray300}
                />
                <path
                  d="M6.25 10.0278L8.75 12.5278L13.75 7.52777"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Must contain one special character</span>
            </div>
            <div className="password-checker">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <rect
                  y="0.027771"
                  width="20"
                  height="20"
                  rx="10"
                  fill={passwordsMatch ? colors.brandGreen : colors.brandGray300}
                />
                <path
                  d="M6.25 10.0278L8.75 12.5278L13.75 7.52777"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Passwords must match</span>
            </div>
          </div>
          <Box
            display="flex"
            justifyContent={'flex-end'}
            alignItems={'center'}
            sx={{
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Button
              title={'Save'}
              variant={'primary'}
              ml="0.5rem"
              disabled={isPasswordDisabled || !passwordsMatch || isPosting}
            />
          </Box>
        </form>
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setIsSnackbarOpen(false)
        }}
      >
        <Alert severity="info">{alertMessage}</Alert>
      </Snackbar>
    </AuthLayoutWrapper>
  )
}

export default ResetPassword
