import { createSlice } from '@reduxjs/toolkit'

import { InterestList } from '../../libs/types/Interest.ts'
import { Provider } from '../../libs/types/Provider.ts'

export interface CampFiltersSort {
  by: string
}

export interface CampFilters {
  kidsAges: number[]
  registrationStatus: {
    open: boolean
    closed: boolean
    full: boolean
    notYetOpen: boolean
  }
  pricePerDay: {
    range: [number, number]
    scholarshipsOnly: boolean
  }
  selectedProviders: string[]
  dayLength: {
    dropOffTimeAm: number[]
    pickUpTimePm: number[]
    length: string
  }
  additionalOptions: {
    hideTentative: boolean
    showOnlyLunchIncluded: boolean
  }
  distance: {
    zipCode: string
    miles: number
    allowOvernight: boolean
    allowBussing: boolean
    isZipCodeIncluded: boolean
  }
  cities: {
    checkedCities: string[]
    includeBussing: boolean
  }
  schoolBreak: string
  schoolBreaks: string[]
  dateRange: [string, string]
  checkedRequirements: { [reqId: number]: { include: boolean; exclude: boolean } }
  overnight: boolean
  calendarDate: string
  searchTerm: string
  checkedInterests: number[]
  checkedCities: string[]
  includeBussing: boolean
}

interface CampFiltersState {
  filters: CampFilters
  sort: CampFiltersSort
  interestList: InterestList[]
  providerList: Provider[]
  filterDrawerOpen: boolean
}

export const initialState: CampFiltersState = {
  filters: {
    kidsAges: [],
    registrationStatus: {
      open: false,
      closed: false,
      full: false,
      notYetOpen: false,
    },
    pricePerDay: {
      range: [0, 350],
      scholarshipsOnly: false,
    },
    selectedProviders: [],
    dayLength: {
      dropOffTimeAm: [4.3, 23.644646],
      pickUpTimePm: [4.3, 23.644646],
      length: 'all',
    },
    additionalOptions: {
      hideTentative: false,
      showOnlyLunchIncluded: false,
    },
    distance: {
      zipCode: '',
      miles: 100,
      allowOvernight: true,
      allowBussing: true,
      isZipCodeIncluded: false,
    },
    cities: {
      checkedCities: [],
      includeBussing: false,
    },
    schoolBreak: 'All',
    schoolBreaks: [],
    dateRange: ['', ''],
    checkedRequirements: {},
    overnight: false,
    calendarDate: '',
    searchTerm: '',
    checkedInterests: [],

    // Deprecated, to be deleted
    checkedCities: [],
    includeBussing: false,
  },
  sort: {
    by: 'starting-date',
  },
  interestList: [],
  providerList: [],
  filterDrawerOpen: false,
}

export const campFiltersReducer = createSlice({
  name: 'campFilters',
  initialState,
  reducers: {
    setKidsAges: (state, action) => {
      state.filters.kidsAges = action.payload
    },
    setRegistrationStatusOpen: (state, action) => {
      state.filters.registrationStatus.open = action.payload
    },
    setRegistrationStatusClosed: (state, action) => {
      state.filters.registrationStatus.closed = action.payload
    },
    setRegistrationStatusFull: (state, action) => {
      state.filters.registrationStatus.full = action.payload
    },
    setRegistrationStatusNotYetOpen: (state, action) => {
      state.filters.registrationStatus.notYetOpen = action.payload
    },
    setRegistrationStatus: (state, action) => {
      state.filters.registrationStatus = action.payload
    },
    setPricePerDayRange: (state, action) => {
      state.filters.pricePerDay.range = action.payload
    },
    setPricePerDayScholarshipsOnly: (state, action) => {
      state.filters.pricePerDay.scholarshipsOnly = action.payload
    },
    setPricePerDay: (state, action) => {
      state.filters.pricePerDay = action.payload
    },
    setDayLengthLength: (state, action) => {
      state.filters.dayLength.length = action.payload
    },
    setDayLengthDropOffTime: (state, action) => {
      state.filters.dayLength.dropOffTimeAm = action.payload
    },
    setDayLengthPickUpTime: (state, action) => {
      state.filters.dayLength.pickUpTimePm = action.payload
    },
    setDayLength: (state, action) => {
      state.filters.dayLength = action.payload
    },
    getInterestList: () => {},
    setInterestList: (state, action) => {
      state.interestList = action.payload
    },
    getProviderList: () => {},
    setProviderList: (state, action) => {
      state.providerList = action.payload
    },
    setProviders: (state, action) => {
      state.filters.selectedProviders = action.payload
    },
    setSchoolBreak: (state, action) => {
      state.filters.schoolBreak = action.payload
    },

    setDistance: (state, action) => {
      state.filters.distance = action.payload
    },
    setDistanceMiles: (state, action) => {
      state.filters.distance.miles = action.payload
    },
    setDistanceIsZipCodeIncluded: (state, action): void => {
      state.filters.distance.isZipCodeIncluded = action.payload
    },
    setDistanceAllowOvernight: (state, action) => {
      state.filters.distance.allowOvernight = action.payload
    },
    setDistanceAllowBussing: (state, action) => {
      state.filters.distance.allowBussing = action.payload
    },
    setDistanceZipCode: (state, action) => {
      state.filters.distance.zipCode = action.payload
    },

    toggleFilterDrawer: (state) => {
      state.filterDrawerOpen = !state.filterDrawerOpen
    },
    setSort: (state, action) => {
      state.sort.by = action.payload
    },
    setCalendarDate: (state, action) => {
      state.filters.calendarDate = action.payload
    },
    resetFilters: (state) => {
      state.filters = initialState.filters
    },
    applyFilters: () => {},
    setSearchTerm: (state, action) => {
      state.filters.searchTerm = action.payload
    },
    getGeolocation: () => {},
    setCheckedInterests: (state, action) => {
      state.filters.checkedInterests = action.payload
    },

    getCheckedCities: () => {},
    setCities: (state, action) => {
      state.filters.cities = action.payload
    },
    setCheckedCities: (state, action) => {
      state.filters.cities.checkedCities = action.payload
    },
    setIncludeBussing: (state, action) => {
      state.filters.cities.includeBussing = action.payload
    },
    setSchoolBreaks: (state, action) => {
      state.filters.schoolBreaks = action.payload
    },
    setHideTentative: (state, action) => {
      state.filters.additionalOptions.hideTentative = action.payload
    },
    setShowOnlyLunchIncluded: (state, action) => {
      state.filters.additionalOptions.showOnlyLunchIncluded = action.payload
    },
    setAdditionalOptions: (state, action) => {
      state.filters.additionalOptions = action.payload
    },
    getDateRange: () => {},
    setDateRange: (state, action) => {
      state.filters.dateRange = action.payload
    },
    setCheckedRequirements: (state, action) => {
      state.filters.checkedRequirements = action.payload
    },
  },
})

export const {
  setInterestList,
  getInterestList,
  setKidsAges,
  setRegistrationStatusOpen,
  setRegistrationStatusClosed,
  setRegistrationStatusFull,
  setRegistrationStatusNotYetOpen,
  setRegistrationStatus,

  setPricePerDayRange,
  setPricePerDayScholarshipsOnly,
  setPricePerDay,

  setProviders,
  getProviderList,
  setProviderList,

  setDayLengthLength,
  setDayLengthDropOffTime,
  setDayLengthPickUpTime,
  setDayLength,
  setSchoolBreak,

  setDistance,
  setDistanceAllowOvernight,
  setDistanceAllowBussing,
  setDistanceZipCode,
  setDistanceMiles,
  setDistanceIsZipCodeIncluded,

  toggleFilterDrawer,
  setSort,
  setCalendarDate,
  resetFilters,
  applyFilters,
  setSearchTerm,
  getGeolocation,
  setCheckedInterests,

  setCities,
  setCheckedCities,
  setIncludeBussing,

  setSchoolBreaks,
  setHideTentative,
  setShowOnlyLunchIncluded,
  setAdditionalOptions,
  setDateRange,
  getDateRange,
  setCheckedRequirements,
} = campFiltersReducer.actions

export default campFiltersReducer.reducer
