import { useScript } from '@hooks'
import mixpanel from 'mixpanel-browser'
import { FC, useEffect } from 'react'
import styled from 'styled-components'

const BlogWrapper = styled.div`
  margin-top: 61px;
  width: 100%;
`

const BlogPage: FC = () => {
  useScript('https://io.dropinblog.com/embedjs/6bb5326d-d5bd-4e16-9522-40fc2232ef37.js')

  useEffect(() => {
    mixpanel.track_pageview({ page: 'BlogPage' })
    const script = document.createElement('script')
    script.src = ''
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <BlogWrapper>
      <div id="dib-posts"></div>
    </BlogWrapper>
  )
}

export default BlogPage
