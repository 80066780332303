import { Icons } from '@assets/svg'
import { brandBlue, brandPurple } from '@config/theme'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'

const themeOptions = createTheme({
  palette: {
    // background: {
    //   default: '#f4f4fc',
    // },
    primary: {
      main: brandBlue,
    },
    secondary: {
      main: brandPurple,
    },
    // error: {
    //   main: '#ff0048',
    // },
    // divider: '#f4f4fc',
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['Inter', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    caption: {
      color: '#999ca9',
    },
    h1: {
      fontFamily: 'Ubuntu',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Ubuntu',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Ubuntu',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Ubuntu',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Ubuntu',
      fontWeight: 700,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 414,
      md: 767,
      lg: 1280,
      xl: 1440,
      // xxl: 1920,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          '&.MuiButton-outlined': {
            borderColor: '#D0D5DD',
            '&:hover': {
              borderColor: '#e0e0e0',
            },
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: brandBlue,
          '& .MuiSlider-thumb': {
            border: '2px solid',
            backgroundColor: '#fff',
            width: 24,
            height: 24,
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: '#fff',
            color: 'black',
            border: `1px solid #e0e0e0`,
            boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
            borderRadius: 8,
            '&:before': {
              display: 'none',
            },
          },
          '& .MuiSlider-valueLabelLabel': {
            fontSize: 12,
          },
          '& .MuiSlider-track': {
            height: 8,
          },
          '& .MuiSlider-rail': {
            height: 8,
            backgroundColor: '#e0e0e0',
          },
          '& .MuiSlider-mark': {
            display: 'none',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 44,
          height: 24,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(20px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: brandBlue,
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: '#e0e0e0',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 20,
            height: 20,
            boxShadow: 'none',
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: '#EAECF0',
            opacity: 1,
            transition: 'background-color 500ms',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          marginRight: 8,
          marginBottom: 8,
          padding: '10px 16px',
          '&.Mui-selected': {
            backgroundColor: brandBlue,
            color: '#fff',
            '&:hover': {
              backgroundColor: brandBlue,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.error': {
            outlineColor: 'red', // Change border color when the input has the class '.error'
          },
        },
      },
    },
    // MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-sizeSmall css-kwgs60-MuiInputBase-root-MuiOutlinedInput-root
    // MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall css-1udluuh-MuiInputBase-input-MuiOutlinedInput-input error
    MuiCheckbox: {
      defaultProps: {
        icon: <Icons.UncheckedIcon />,
        checkedIcon: <Icons.CheckedIcon />,
        indeterminateIcon: <Icons.IndeterminateIcon />,
      },
    },
  },
})

export const camperoniMuiTheme = responsiveFontSizes(themeOptions)
