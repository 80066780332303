import { getCurrentDate, getDatesFromStartDateAndEndDate, getMonthFromStartDate } from '@decorators/school-breaks'
import { api, apiResponse } from '@libs/api'
import { call, CallEffect, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects'

import { Schoolbreak } from '../../libs/types/Schoolbreak.ts'
import { getSchoolBreakList, setSchoolBreakList, setSchoolBreakLoading } from './schoolBreaksSlice.ts'

function* schoolBreakList(): Generator<CallEffect | PutEffect | SelectEffect, void | any> {
  yield put(setSchoolBreakLoading(true))
  const { data } = (yield call(api.getSchoolBreaks)) as apiResponse<Schoolbreak[]>

  data.map((item: Schoolbreak) => {
    item.is_active = true
    item.month = getMonthFromStartDate(item.start_date)
    item.dates = getDatesFromStartDateAndEndDate(item.start_date, item.end_date)
  })

  const schoolBreaks = data
    .filter((schoolbreak) => schoolbreak.end_date > getCurrentDate())
    .sort((a, b) => {
      return new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
    })

  yield put(setSchoolBreakList(schoolBreaks))
  yield put(setSchoolBreakLoading(false))
}

export default function* schoolBreakSaga() {
  yield takeLatest(getSchoolBreakList, schoolBreakList)
}
