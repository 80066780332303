import { createSlice } from '@reduxjs/toolkit'

import { Purchaseoption } from '../../libs/types/Purchaseoption.ts'
import { Requirement } from '../../libs/types/Requirement'

export interface CampPageState {
  requirementList: Requirement[]
  purchaseOptions: Purchaseoption[]
}

export const initialState: CampPageState = {
  requirementList: [],
  purchaseOptions: [],
}

export const campPageReducer = createSlice({
  name: 'campPage',
  initialState,
  reducers: {
    getRequirementList: () => {},
    setRequirementList: (state, action) => {
      state.requirementList = action.payload
    },
    getPurchaseOptions: () => {},
    setPurchaseOptions: (state, action) => {
      state.purchaseOptions = action.payload
    },
  },
})

export const { getPurchaseOptions, setPurchaseOptions, getRequirementList, setRequirementList } =
  campPageReducer.actions

export default campPageReducer.reducer
