import { Logo } from '@stories/Theme/Logo/Logo'
import { FC, ReactNode } from 'react'

import Ellipse from '../components/Ellipse'
import { EllipseEnum } from '../libs/types/Ellipse'
import { AuthLayoutWrapper } from './style'

const AuthLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <AuthLayoutWrapper>
      <div className="auth-nav">
        <Logo />

        <Ellipse type={EllipseEnum.auth} />
      </div>

      <div className="auth-content">{children}</div>
    </AuthLayoutWrapper>
  )
}

export default AuthLayout
