import {
  setDayLengthDropOffTime,
  setDayLengthLength,
  setDayLengthPickUpTime,
} from '@store/campFilters/campFiltersSlice.ts'
import { useAppDispatch } from '@store/hooks.ts'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useDayLengthFilters = (): void => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()
  const filter_day_length = searchParams.get('filter_day_length')
  const filter_day_length_drop_off_time = searchParams.get('filter_day_length_drop_off_time')
  const filter_day_length_pick_up_time = searchParams.get('filter_day_length_pick_up_time')

  useEffect(() => {
    if (filter_day_length && ['all', 'full', 'halfAm', 'halfPm', 'overnight'].includes(filter_day_length)) {
      dispatch(setDayLengthLength(filter_day_length))
      const params = new URLSearchParams(searchParams)
      params.delete('filter_day_length')
      const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
      window.history.replaceState(null, '', url)
    }
  }, [filter_day_length])

  useEffect(() => {
    if (filter_day_length_drop_off_time) {
      try {
        const filterDayLengthDropOffTime = JSON.parse(filter_day_length_drop_off_time) || []
        if (Array.isArray(filterDayLengthDropOffTime)) {
          if (filterDayLengthDropOffTime[0].toString().toLowerCase() === 'any') {
            filterDayLengthDropOffTime[0] = 4.3
          }
          if (filterDayLengthDropOffTime[1].toString().toLowerCase() === 'any') {
            filterDayLengthDropOffTime[1] = 23.644646
          }

          dispatch(setDayLengthDropOffTime(filterDayLengthDropOffTime))
          const params = new URLSearchParams(searchParams)
          params.delete('filter_day_length_drop_off_time')
          const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
          window.history.replaceState(null, '', url)
        }
      } catch (e) {
        console.warn('we tried to set DropOffTime but failed')
      }
    }
  }, [filter_day_length_drop_off_time])

  useEffect(() => {
    if (filter_day_length_pick_up_time) {
      try {
        const filterDayLengthPickUpTime = JSON.parse(filter_day_length_pick_up_time) || []
        if (Array.isArray(filterDayLengthPickUpTime)) {
          if (filterDayLengthPickUpTime[0].toString().toLowerCase() === 'any') {
            filterDayLengthPickUpTime[0] = 4.3
          }
          if (filterDayLengthPickUpTime[1].toString().toLowerCase() === 'any') {
            filterDayLengthPickUpTime[1] = 23.644646
          }

          dispatch(setDayLengthPickUpTime(filterDayLengthPickUpTime))
          const params = new URLSearchParams(searchParams)
          params.delete('filter_day_length_pick_up_time')
          const url = params.toString() ? `/camps?${params.toString()}` : `/camps`
          window.history.replaceState(null, '', url)
        }
      } catch (e) {
        console.warn('we tried to set PickUpTime but failed')
      }
    }
  }, [filter_day_length_pick_up_time])
}
