import { Grid, Skeleton, Stack } from '@mui/material'
import React from 'react'

const CampPageSkeleton: React.FC = () => {
  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2 }} padding={'36px'} rowSpacing={2} mt={6}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Skeleton variant="rounded" width={'100%'} sx={{ height: { sm: 200, md: 400 } }} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: { sm: 0, md: 10 } }}>
        <Stack spacing={2}>
          <Skeleton variant="rounded" width={'100%'} sx={{ height: { sm: 120, md: 250 } }} />
          <Skeleton variant="rounded" width={'100%'} sx={{ height: { sm: 60, md: 100 } }} />
          <Skeleton variant="rounded" width={'100%'} sx={{ height: { sm: 30, md: 500 } }} />
        </Stack>
      </Grid>
    </Grid>
  )
}

export default CampPageSkeleton
