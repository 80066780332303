export const registrationStatus = (
  registrationStatus: 'OPEN' | 'CLOSED' | 'FULL' | 'NOT YET OPEN' | 'WAITLIST'
): string => {
  const dictionary = {
    OPEN: 'Open',
    CLOSED: 'Closed',
    FULL: 'Full Day',
    WAITLIST: 'Wait list',
    'NOT YET OPEN': 'Not yet open',
  }
  return dictionary[registrationStatus]
}
