import { colors } from '@config/theme'
import { api } from '@libs/api'
import { Alert, Box, Snackbar, useMediaQuery } from '@mui/material'
import { Button } from '@stories/Theme/Button/Button'
import { Input } from '@stories/Theme/Input/Input'
import { Text } from '@stories/Typography/Text/Text'
import mixpanel from 'mixpanel-browser'
import React, { useEffect, useState } from 'react'
import { FcGoogle } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'

import { googleProvider } from '../libs/GoogleOAuthProvider.ts'
import redis from '../libs/lockr.ts'
import { User } from '../libs/types/User.ts'

const Settings = () => {
  useEffect(() => {
    mixpanel.track_pageview({ page: 'Settings' })
  }, [])

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const navigator = useNavigate()
  useEffect(() => {
    const isLoggedIn = api.isUserLoggedIn()
    if (!isLoggedIn) {
      navigator('/login')
    }
    const user = redis.get('user') as User
    setFirstName(user.first_name)
    setLastName(user.last_name)
    setEmail(user.email)
  }, [])

  const [isPosting, setIsPosting] = useState<boolean>(false)
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')

  const updateProfileSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsPosting(true)
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    const { first_name, last_name } = Object.fromEntries(formData.entries()) as {
      first_name: string
      last_name: string
    }
    api
      .updateUserAccount(`${first_name} ${last_name}`)
      .then(() => {
        const user = redis.get('user') as User
        user.first_name = first_name
        user.last_name = last_name
        redis.set('user', user)

        setIsSnackbarOpen(true)
        setAlertMessage('Your Personal info has been successfully changed')
      })
      .catch(() => {
        setIsSnackbarOpen(true)
        setAlertMessage('Unknown Error')
      })
      .finally(() => setIsPosting(false))
  }

  const updatePasswordForm = (event: React.FormEvent<HTMLFormElement>) => {
    setIsPosting(true)
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    const { old_password, new_password1, new_password2 } = Object.fromEntries(formData.entries()) as {
      old_password: string
      new_password1: string
      new_password2: string
    }
    api
      .updateUserPassword(old_password, new_password1, new_password2)
      .then(() => {
        setIsSnackbarOpen(true)
        setAlertMessage('Your Password has been successfully changed')
      })
      .catch((error) => {
        const response = error.response.data

        const keys = Object.keys(response)
        // @ts-ignore
        for (const [index, key] of keys.entries()) {
          if (index >= 0) {
            setIsSnackbarOpen(true)
            setAlertMessage(response[key])
            break
          }
        }
      })
      .finally(() => setIsPosting(false))
  }

  const googleConnect = googleProvider.useGoogleLogin({
    flow: 'auth-code',
    onSuccess: (authResponse: { code: string }) => {
      api
        .googleConnect(authResponse)
        .then(() => {
          setIsSnackbarOpen(true)
          setAlertMessage('Your Google account has been connected successfully')
        })
        .catch(() => {
          setIsSnackbarOpen(true)
          setAlertMessage('Something went wrong!')
        })
    },
    onError: (err: any) => console.error('Failed to login with google', err),
  })

  return (
    <>
      <Box sx={{ padding: isSmallScreen ? '3rem 1.5rem' : '5rem 6rem' }}>
        <Text
          variant={'h1'}
          fontFamily="Ubuntu"
          fontSize={isSmallScreen ? '30px' : '48px'}
          fontWeight="bold"
          color={colors.brandGray900}
          mb={isSmallScreen ? '2rem' : '4rem'}
        >
          Settings
        </Text>
        <Box sx={{ paddingBottom: '1rem', borderBottom: `1px solid ${colors.brandGray50}` }}>
          <Text
            variant={'h3'}
            fontSize={isSmallScreen ? '18px' : '24px'}
            fontWeight="semibold"
            color={colors.brandGray900}
            mb="0.4rem"
          >
            Personal Info
          </Text>
          <Text variant={'h6'} fontSize={isSmallScreen ? '14px' : '16px'} color={colors.brandGray}>
            Update your personal details here.
          </Text>
        </Box>
        <form id={'update-profile-form'} onSubmit={updateProfileSubmit}>
          <Box
            display="flex"
            alignItems={!isSmallScreen ? 'center' : ''}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{
              paddingBottom: '1rem',
              borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Text
              style={{ width: isSmallScreen ? 'auto' : '280px' }}
              variant={'label'}
              fontSize={'14px'}
              fontWeight="semibold"
              color={colors.brandGray700}
              mb={isSmallScreen ? '0.75rem' : ''}
            >
              First name
            </Text>
            <Input
              type="text"
              placeholder="Name."
              name={'first_name'}
              onChange={(event) => setFirstName(event.target.value)}
              style={{ width: isSmallScreen ? '100%' : '484px' }}
              value={firstName}
              disabled={isPosting}
            />
          </Box>

          <Box
            display="flex"
            alignItems={!isSmallScreen ? 'center' : ''}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{
              paddingBottom: '1rem',
              borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Text
              style={{ width: isSmallScreen ? 'auto' : '280px' }}
              variant={'label'}
              fontSize={'14px'}
              fontWeight="semibold"
              color={colors.brandGray700}
              mb={isSmallScreen ? '0.75rem' : ''}
            >
              Last name
            </Text>
            <Input
              type="text"
              placeholder="Surname."
              name={'last_name'}
              style={{ width: isSmallScreen ? '100%' : '484px' }}
              onChange={(event) => setLastName(event.target.value)}
              value={lastName}
              disabled={isPosting}
            />
          </Box>
          <Box
            display="flex"
            alignItems={!isSmallScreen ? 'center' : ''}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{
              paddingBottom: '1rem',
              borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Text
              style={{ width: isSmallScreen ? 'auto' : '280px' }}
              variant={'label'}
              fontSize={'14px'}
              fontWeight="semibold"
              color={colors.brandGray700}
              mb={isSmallScreen ? '0.75rem' : ''}
            >
              Email
            </Text>
            <Input
              type="text"
              placeholder="micheal@gmail.com"
              onChange={() => setEmail('')}
              style={{ width: isSmallScreen ? '100%' : '484px' }}
              value={email}
              disabled={true}
            />
          </Box>
          {/*<Box*/}
          {/*  display="none"*/}
          {/*  alignItems={!isSmallScreen ? 'center' : ''}*/}
          {/*  flexDirection={isSmallScreen ? 'column' : 'row'}*/}
          {/*  sx={{*/}
          {/*    paddingBottom: '1rem',*/}
          {/*    borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,*/}
          {/*    paddingY: isSmallScreen ? '0.75rem' : '1.7rem',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Text*/}
          {/*    style={{ width: isSmallScreen ? 'auto' : '280px' }}*/}
          {/*    variant={'label'}*/}
          {/*    fontSize={'14px'}*/}
          {/*    fontWeight="semibold"*/}
          {/*    color={colors.brandGray700}*/}
          {/*    mb={isSmallScreen ? '0.75rem' : ''}*/}
          {/*  >*/}
          {/*    Your Photo*/}
          {/*  </Text>*/}
          {/*  <Box*/}
          {/*    display={'flex'}*/}
          {/*    alignContent="flex-start"*/}
          {/*    flexDirection={isSmallScreen ? 'column' : 'row'}*/}
          {/*    sx={{ gap: 2 }}*/}
          {/*  >*/}
          {/*    <Avatar size="medium">GG</Avatar>*/}
          {/*    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />*/}
          {/*    <Box*/}
          {/*      sx={{*/}
          {/*        width: isSmallScreen ? '100%' : '428px',*/}
          {/*        gap: 1,*/}
          {/*        border: `1px solid ${colors.brandGray50}`,*/}
          {/*        cursor: 'pointer',*/}
          {/*        textAlign: 'center',*/}
          {/*      }}*/}
          {/*      display={'flex'}*/}
          {/*      flexDirection={'column'}*/}
          {/*      alignItems={'center'}*/}
          {/*      justifyContent={'center'}*/}
          {/*      px={1}*/}
          {/*      py={2}*/}
          {/*      borderRadius={1}*/}
          {/*      onClick={handleButtonClick}*/}
          {/*    >*/}
          {/*      <UploadIcon />*/}
          {/*      <Text*/}
          {/*        style={{ width: isSmallScreen ? 'auto' : '280px' }}*/}
          {/*        variant={'p'}*/}
          {/*        fontSize={'14px'}*/}
          {/*        color={colors.brandGray}*/}
          {/*      >*/}
          {/*        <Text*/}
          {/*          style={{ width: isSmallScreen ? 'auto' : '280px' }}*/}
          {/*          variant={'span'}*/}
          {/*          fontSize={'14px'}*/}
          {/*          fontWeight="semibold"*/}
          {/*          color={colors.brandBlue}*/}
          {/*        >*/}
          {/*          Click to upload{' '}*/}
          {/*        </Text>*/}
          {/*        or drag and drop*/}
          {/*      </Text>*/}
          {/*      <Text*/}
          {/*        style={{ width: isSmallScreen ? 'auto' : '280px' }}*/}
          {/*        variant={'p'}*/}
          {/*        fontSize={'14px'}*/}
          {/*        color={colors.brandGray}*/}
          {/*      >*/}
          {/*        SVG, PNG, JPG or GIF (max. 800x400px)*/}
          {/*      </Text>*/}
          {/*    </Box>*/}
          {/*  </Box>*/}
          {/*</Box>*/}

          <Box
            display="flex"
            justifyContent={'flex-end'}
            alignItems={'center'}
            sx={{
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Button title={'Save'} variant={'primary'} ml="0.5rem" disabled={isPosting} />
          </Box>
        </form>

        <Box sx={{ paddingY: '1rem', borderBottom: `1px solid ${colors.brandGray50}` }}>
          <Text
            variant={'h3'}
            fontSize={isSmallScreen ? '18px' : '24px'}
            fontWeight="semibold"
            color={colors.brandGray900}
            mb="0.4rem"
          >
            Connect your google account
          </Text>
        </Box>

        <Box
          display="flex"
          alignItems={!isSmallScreen ? 'center' : ''}
          flexDirection={isSmallScreen ? 'column' : 'row'}
          justifyContent={'space-between'}
          sx={{
            paddingBottom: '1rem',
            borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
            paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
          }}
        >
          <Text
            style={{ width: isSmallScreen ? 'auto' : '280px' }}
            variant={'label'}
            fontSize={'14px'}
            fontWeight="semibold"
            color={colors.brandGray700}
            mb={isSmallScreen ? '0.75rem' : ''}
          >
            Connect your Google Account in order to sign in with Google.
          </Text>
          <Button
            variant="secondary"
            icon={<FcGoogle />}
            title="Connect"
            mb="1rem"
            rounded
            size="large"
            onClick={() => googleConnect()}
          />
        </Box>

        <Box sx={{ paddingY: '1rem', borderBottom: `1px solid ${colors.brandGray50}` }}>
          <Text
            variant={'h3'}
            fontSize={isSmallScreen ? '18px' : '24px'}
            fontWeight="semibold"
            color={colors.brandGray900}
            mb="0.4rem"
          >
            Change Password
          </Text>
        </Box>

        <form id={'update-password-form'} onSubmit={updatePasswordForm}>
          <Box
            display="flex"
            alignItems={!isSmallScreen ? 'center' : ''}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{
              paddingBottom: '1rem',
              borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Text
              style={{ width: isSmallScreen ? 'auto' : '280px' }}
              variant={'label'}
              fontSize={'14px'}
              fontWeight="semibold"
              color={colors.brandGray700}
              mb={isSmallScreen ? '0.75rem' : ''}
            >
              Old password
            </Text>
            <Input
              type="password"
              placeholder="*****"
              style={{ width: isSmallScreen ? '100%' : '484px' }}
              name={'old_password'}
              onChange={() => {}}
              disabled={isPosting}
            />
          </Box>
          <Box
            display="flex"
            alignItems={!isSmallScreen ? 'center' : ''}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{
              paddingBottom: '1rem',
              borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Text
              style={{ width: isSmallScreen ? 'auto' : '280px' }}
              variant={'label'}
              fontSize={'14px'}
              fontWeight="semibold"
              color={colors.brandGray700}
              mb={isSmallScreen ? '0.75rem' : ''}
            >
              New password
            </Text>
            <Input
              type="password"
              placeholder="*****"
              style={{ width: isSmallScreen ? '100%' : '484px' }}
              onChange={() => {}}
              name={'new_password1'}
              disabled={isPosting}
            />
          </Box>
          <Box
            display="flex"
            alignItems={!isSmallScreen ? 'center' : ''}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{
              paddingBottom: '1rem',
              borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Text
              style={{ width: isSmallScreen ? 'auto' : '280px' }}
              variant={'label'}
              fontSize={'14px'}
              fontWeight="semibold"
              color={colors.brandGray700}
              mb={isSmallScreen ? '0.75rem' : ''}
            >
              Re-enter new password
            </Text>
            <Input
              type="password"
              placeholder="*****"
              style={{ width: isSmallScreen ? '100%' : '484px' }}
              onChange={() => {}}
              name={'new_password2'}
              disabled={isPosting}
            />
          </Box>
          <Box
            display="flex"
            justifyContent={'flex-end'}
            alignItems={'center'}
            sx={{
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Button title={'Save'} variant={'primary'} ml="0.5rem" />
          </Box>
        </form>
      </Box>

      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setIsSnackbarOpen(false)
        }}
      >
        <Alert severity="info">{alertMessage}</Alert>
      </Snackbar>
    </>
  )
}

export default Settings
